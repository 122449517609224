import { useRef,useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { LegendUpload } from './LegendUpload';
import { Connector } from './Connector';
import { RuleBuilder } from './RuleBuilder';

import "./style.module.css";
import 'primeicons/primeicons.css';
import { Card } from 'primereact/card';

export const LegendConnector = (props) => {
    const {UUID,        
        translator,
        legendTemplate,
        options,
        blocks,
        blockLookUp,
        characteristics,
        characteristicLookUp
    } = props;

    const [fileContent, setFileContent] = useState([]);
    const [referenceLegend, setReferenceLegend] = useState<string>(null);
    const [visibleLegendUpload, setLegendUploadVisible] = useState<boolean>(true);
    const [visibleConnector, setConnectorVisible] = useState<boolean>(false);
    const [visibleRules, setRulesVisible] = useState<boolean>(false);
    const [visibleRuleBuilder, setVisibleRuleBuilder] = useState<boolean>(false);
    return (
        <>            
            {/*<Sidebar visible={visibleRuleBuilder} onHide={() => setVisibleRuleBuilder(false)} position="right" className="w-10" header="Rule Builder">
                <RuleBuilder />
            </Sidebar>
            <i className="pi pi-sliders-v" style={{ position: 'absolute', bottom: '20px', right: '20px', zIndex: 2, cursor: 'pointer' }} onClick={() => setVisibleRuleBuilder(true)}></i>*/}
            {visibleLegendUpload && <LegendUpload
                UUID={UUID}
                translator={translator}
                legendTemplate={legendTemplate}
                options={options}
                blocks={blocks}
                blockLookUp={blockLookUp}
                characteristics={characteristics}
                characteristicLookUp={characteristicLookUp}
                referenceLegend={referenceLegend}
                setReferenceLegend={setReferenceLegend}
                fileContent={fileContent}
                setFileContent={setFileContent}
                setLegendUploadVisible={setLegendUploadVisible}
                setConnectorVisible={setConnectorVisible}
                />}
            {visibleConnector && <Connector
                UUID={UUID}
                translator={translator}
                legendTemplate={legendTemplate}
                options={options}
                blocks={blocks}
                blockLookUp={blockLookUp}
                characteristics={characteristics}
                characteristicLookUp={characteristicLookUp}
                referenceLegend={referenceLegend}
                setReferenceLegend={setReferenceLegend}
                fileContent={fileContent}
                setFileContent={setFileContent}
                setLegendUploadVisible={setLegendUploadVisible}
                setConnectorVisible={setConnectorVisible}
                />}
        </>
    )
}
