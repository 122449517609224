import { useState,useRef,useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { MermaidChart } from './MermaidChart';
import MermaidChartRender from './MermaidChartRender';
import * as htmlToImage from 'html-to-image';
import { Builder } from 'xml2js';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Toast } from 'primereact/toast';

import './flow.css';

export const TabsComponent = (props) => {
  const { rerenderTrigger,translator,blocks,LC_Legend,LC_Class,LC_ClassCharacteristics,LC_HorizontalPatterns,LC_Strata,LC_Properties,characteristics,characteristicLookUp,LC_Characteristics,legendValid,activeLCElement,setActiveLCElement,triggerFullExport,setFullExport } = props;  
  const ClassTabs = useRef([]);
  const [flowDefinition,setFlowDefinition] = useState<any>(``);
  useEffect(()=>{    
    let classes = [];
    let indvClasses = [];
    let styles = `style A fill:#91AEC4,stroke:#333,stroke-width:2px,color:#fff`;
    let edits = `click A call emptyCall("Legend","A_1") "Edit Legend Information"`;
    let filterd_classes = LC_Class?.current.filter( clss => clss.legend_id === LC_Legend?.current["id"] );
    if(filterd_classes !== undefined){
        Object.keys(filterd_classes).forEach((key0) => {
            let class_id = filterd_classes[key0].class_id;
            let DisplayClassCharacteristics = ``;            
            if(LC_ClassCharacteristics.current[class_id] !== undefined){
                Object.entries(LC_ClassCharacteristics.current[class_id]).forEach((ClassXteristic)=>{
                    if(ClassXteristic[0] !== "legend_id" && ClassXteristic[0] !== "id" && ClassXteristic[1][ClassXteristic[0]+'-name'] !== undefined && ClassXteristic[1][ClassXteristic[0]+'-name'] !== ""){
                        DisplayClassCharacteristics = DisplayClassCharacteristics+`
                                            `           +String(ClassXteristic[0]).replace(/[`()<>\{\}\[\]\\\/]/gi, '').charAt(0).toUpperCase()+String(ClassXteristic[0]).replace(/[`()<>\{\}\[\]\\\/]/gi, '').slice(1).replaceAll("_"," ")+`: `+String(ClassXteristic[1][ClassXteristic[0]+'-name']).replace(/[`()<>\{\}\[\]\\\/]/gi, '');
                    }                    
                });
            }

            let filterd_HPs = LC_HorizontalPatterns?.current.filter( HP => HP.class_id === class_id );
            let patterns = [];
            let strata = [];
            let elementals = [];
            if(filterd_HPs !== undefined){
                Object.keys(filterd_HPs).forEach((key1) => {
                    let filterd_strata = LC_Strata?.current.filter( stratum => stratum.HPID === filterd_HPs[key1]["horizontal_pattern_id"] );
                    if(filterd_strata !== undefined){            
                        Object.keys(filterd_strata).forEach((key2) => {
                            let filterd_elements = LC_Properties?.current.filter( properties => properties.StratumID === filterd_strata[key2]["stratumID"] );
                            Object.keys(filterd_elements).forEach((key3) => {
                                let filterd_blocks = blocks?.current["LC_Block"].filter( block => block.block_id === filterd_elements[key3]["BlockID"] );
                                let filterd_xteristics = LC_Characteristics?.current.filter( characteristics => characteristics.StratumID === filterd_strata[key2]["stratumID"] && characteristics.BlockID === filterd_blocks[0]["block_id"] );
                                let character = "";
                                if(filterd_xteristics.length > 0){
                                    character = `E_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`_`+filterd_blocks[0]["block_id"]+` -.- F_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`_`+filterd_blocks[0]["block_id"]+'_'+filterd_xteristics.length+`[["fa:fa-paperclip `;
                                    Object.values(filterd_xteristics).forEach((entry) => {
                                        Object.entries(entry).forEach((XStic)=>{
                                            if(XStic[0] === "CharacteristicLabel" || XStic[0] === "name"){
                                                character = character+`
                                                                &lcub;`+XStic[1]+`&rcub;`;
                                            }
                                            else if(XStic[0] !== "StratumID" && XStic[0] !== "stratumID" && XStic[0] !== "BlockID" && XStic[0] !== "BlockReference" && XStic[0] !== "CharacteristicID" && XStic[0] !== "CharacteristicReference" && XStic[0] !== "description" && XStic[1] !== undefined && XStic[1] !== ""){
                                                let leftBracket = "";
                                                let rightBracket = "";
                                                if(Array.isArray(XStic[1])){
                                                    leftBracket = "&lsqb;";
                                                    rightBracket = "&rsqb;";
                                                    XStic[1] = XStic[1][0]+" "+XStic[1][1];
                                                }
                                                character = character+`
                                                            `+XStic[0]+` - `+leftBracket+String(XStic[1]).replace(/[`()<>\{\}\[\]\\\/]/gi, '')+rightBracket;
                                            }   
                                        });
                                    });
                                    character = character+`"]]`;
                                    styles = styles+`
                                    style F_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`_`+filterd_blocks[0]["block_id"]+'_'+filterd_xteristics.length+` fill:#fff4dd, stroke:#6366f1, stroke-width:0.5px`;
                                    edits = edits+`
                                    click F_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`_`+filterd_blocks[0]["block_id"]+'_'+filterd_xteristics.length+` call emptyCall("Characteristics","F_1_1_1_1_1_1_1") "Edit Characteristics"`;
                                }                                
                                elementals = [...elementals, [ `D_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+"_"+filterd_strata[key2]["stratumID"]+` --- E_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`_`+filterd_blocks[0]["block_id"]+`("fa:`+filterd_blocks[0]["block_icon"].slice(25)+` `+String(filterd_blocks[0]["block_label"]).replace(/[`()<>\{\}\[\]\\\/]/gi, '')+`
                                                                                                                                                                                                                                                                                                                                Presence Type: `+filterd_elements[key3]["elementPresenceType"]+`
                                                                                                                                                                                                                                                                                                                                Cover: `+filterd_elements[key3]["cover"]+`%")`, character ]];
                                styles = styles+`
                                style E_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`_`+filterd_blocks[0]["block_id"]+` fill:#fff4dd, stroke:#6366f1, stroke-width:0.5px`;
                                edits = edits+`
                                click E_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`_`+filterd_blocks[0]["block_id"]+` call emptyCall("Property","E_1_1_1_1_1_1") "Edit Property"`;
                            });                            
                            strata = [...strata, [ `C_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+` --- D_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`_`+filterd_strata[key2]["stratumID"]+`(["fa:fa-minus `+filterd_strata[key2]["name"]+`
                                                                                                                                                                                            Presence Type: `+filterd_strata[key2]["presenceType"]+`"])`, ...elementals ]];
                            styles = styles+`
                            style D_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+"_"+filterd_strata[key2]["stratumID"]+` text-align:left, stroke:#6366f1, stroke-width:0.5px`;
                            edits = edits+`
                            click D_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+"_"+filterd_strata[key2]["stratumID"]+` call emptyCall("Stratum","D_1_1_1_1_1") "Edit Stratum"`;
                            elementals = [];
                        });
                        patterns = [...patterns, [ `A`+class_id+` --- C_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+`([fa:fa-bars `+String(filterd_HPs[key1]["name"]).replace(/[`()<>\{\}\[\]\\\/]/gi, '')+`
                                                                                                                    Cover: `+filterd_HPs[key1]["cover"]+`%
                                                                                                                    Occurrence: `+filterd_HPs[key1]["occurrence"]+`%])`, ...strata ]];
                        styles = styles+`
                        style C_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+` text-align:left, stroke:#6366f1, stroke-width:0.5px`;
                        edits = edits+`
                        click C_1_`+class_id+`_1_`+filterd_HPs[key1]["horizontal_pattern_id"]+` call emptyCall("Horizontal Pattern","C_1_1_1_1") "Edit Horizontal Pattern"`;
                    }
                    strata = [];
                });
            }

            classes = [...classes, 
                                [
                                    `A(fa:fa-briefcase `+LC_Legend.current.legend_name.replace(/[`()<>\{\}\[\]\\\/]/gi, '')+`) --- A`+class_id+`("fa:fa-folder-open `+String(filterd_classes[key0]["class_name"]).replace(/[`()<>\{\}\[\]\\\/]/gi, '')+`
                                                                                                        Code: `+String(filterd_classes[key0]["class_map_code"]).replace(/[`()<>\{\}\[\]\\\/]/gi, '')+`")`,
                                    [
                                        [
                                            `A`+class_id+` -....- B`+class_id+`[[fa:fa-gears Class Characteristics:`+DisplayClassCharacteristics+` ]]`
                                        ],
                                        ...patterns
                                    ] 
                                ]
                            ];
            indvClasses = [...indvClasses, 
                              [
                                  `A`+class_id+`("fa:fa-folder-open `+String(filterd_classes[key0]["class_name"]).replace(/[`()<>\{\}\[\]\\\/]/gi, '')+`
                                                                                                      Code: `+String(filterd_classes[key0]["class_map_code"]).replace(/[`()<>\{\}\[\]\\\/]/gi, '')+`")`,
                                  [
                                      [
                                          `A`+class_id+` -....- B`+class_id+`[[fa:fa-gears Class Characteristics:`+DisplayClassCharacteristics+` ]]`
                                      ],
                                      ...patterns
                                  ] 
                              ]
                          ];                
            styles = styles+`
            style A`+class_id+` stroke:#6366f1, stroke-width:0.5px
            style B`+class_id+` text-align:left, fill:#eee, stroke:#6366f1, stroke-width:0.5px`;
            edits = edits+`
            click A`+class_id+` call emptyCall("Class","A_1_1") "Edit Class Information"`;
        });
    };
    
    let subFlow = "";    
    classes.flat(Infinity).map((line)=>{
        subFlow = subFlow+`
                `+line;
    });
    let buildFlow = `flowchart LR   
    `+subFlow+`
    `+styles+`
    `+edits;
    setFlowDefinition(buildFlow);

    ClassTabs.current = [];
    indvClasses.map((clss,index)=>{
      let subFlow = "";
      clss.flat(Infinity).map((line)=>{
          subFlow = subFlow+`
                  `+line;          
      });
      let buildFlow = `flowchart LR  
      `+subFlow+`
      `+edits;
      ClassTabs.current = [...ClassTabs.current, { index: index, title: String(filterd_classes[index]["class_name"]), content: buildFlow }];
    });
  },[rerenderTrigger]);

  let translation = null;
  const genLChS = () => {
    let ClassXteristic = {};
    Object.entries(LC_ClassCharacteristics.current).forEach((Xteristic)=>{
        ClassXteristic = {...ClassXteristic, ["_"+Xteristic[0]]:Xteristic[1]};
    });
    let Legend = {LC_Legend: {
                        $: {...LC_Legend.current},
                        objects: {
                            LC_Class: LC_Class.current,
                            LC_ClassCharacteristics: ClassXteristic,
                            LC_HorizontalPatterns: LC_HorizontalPatterns.current,
                            LC_Strata: LC_Strata.current,
                            LC_Properties: LC_Properties.current,
                            LC_Characteristics: LC_Characteristics.current
                        }   
                    }
                };
    const builder = new Builder();
    const xml = builder.buildObject(Legend);        
    return xml;
  }
  const genCSV = () => {
      translation = translator['csv'];
      let TranslatedClass = [];
      let buildTranslatedClass = {};
      Object.values(LC_Class.current).forEach((clss)=>{
          buildTranslatedClass = {};
          Object.values(translation['LC_Class']).forEach((translation_element)=>{
              buildTranslatedClass = {...buildTranslatedClass, [translation_element['Translation']]: clss[translation_element['LChS_EquivalentElement']]};               
          });                
          let filterd_HPs = LC_HorizontalPatterns?.current.filter( HP => HP.class_id === clss['class_id'] );
          let elementals = [];
          let displayElements = "";
          if(filterd_HPs !== undefined){
              Object.keys(filterd_HPs).forEach((key0) => {
                  let filterd_strata = LC_Strata?.current.filter( stratum => stratum.HPID === filterd_HPs[key0]["horizontal_pattern_id"] );
                  if(filterd_strata !== undefined){            
                      Object.keys(filterd_strata).forEach((key1) => {
                          let filterd_elements = LC_Properties?.current.filter( properties => properties.StratumID === filterd_strata[key1]["stratumID"] );
                          Object.keys(filterd_elements).forEach((key2) => {
                              let filterd_blocks = blocks?.current["LC_Block"].filter( block => block.block_id === filterd_elements[key2]["BlockID"] );
                              elementals = [...elementals, filterd_blocks[0]["block_reference"]+";"];
                          });
                          let unique = new Set(elementals);
                          unique.forEach((element)=>{
                              displayElements = displayElements+" "+element;
                          });
                          buildTranslatedClass = {...buildTranslatedClass, Elements: displayElements}; 
                          elementals = [];
                      });
                  }
              });
          }
          TranslatedClass.push(buildTranslatedClass);
      });
      const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
      const header = Object.keys(TranslatedClass[0]);
      const csv = [
                      header.join(','), // header row first
                      ...TranslatedClass.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                  ].join('\r\n');
      return csv;
  }

  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  var image = null;
  const altCaptureTabImage = async (index) => {    
    const svgElement = document.getElementById(index).querySelector('svg');
    if (svgElement) {
      const svgData = new XMLSerializer().serializeToString(svgElement);
      const image = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
      return image;
    }
    else
      return '';
  };
  const captureTabImage = async (index) => {    
    await wait(500); // Wait for the tab to render
    let alt = false;
    image = await htmlToImage.toPng(document.getElementById(index)).catch(function (error) {
      console.error('Trying Again!', error);
      alt = true;      
      toast.current.show({ severity: 'info', summary: 'PNG Export Failed', detail: "Export of diagram "+index+" failed. Changing format to SVG.", life: 5000 });
    });
    if(alt)
        image = await altCaptureTabImage(index);
    return image;
  };
  const fullExport = async () => {
    setLoading(true);        
    const LChS = new Blob([genLChS()], {type: 'text/xml'});
    const csv = new Blob([genCSV()], {type: 'text/csv'});

    const originalTabIndex = tabIndex;
    await setTabIndex(0); // Switch to first tab
    const dataUrl0 = await captureTabImage("Full Legend");
    const dataUrls = [];
    for (let i = 0; i < LC_Class.current.length; i++) {
        let curr = i+1;
        await setTabIndex(curr);
        dataUrls[i] = await captureTabImage(LC_Class.current[i]["class_name"]);
    }
    await setTabIndex(LC_Class.current.length); // Switch to first tab                
    setTabIndex(originalTabIndex); // Switch back to original tab
    
    const zip = new JSZip();    
    for (let i = 0; i < LC_Class.current.length; i++) {
        if(dataUrls[i].type == "image/svg+xml;charset=utf-8")
            zip.file(LC_Class.current[i]["class_name"].replace(/[`()<>\{\}\[\]\\\/]/gi, '')+'.svg', dataUrls[i]);
        else
            zip.file(LC_Class.current[i]["class_name"].replace(/[`()<>\{\}\[\]\\\/]/gi, '')+'.png', dataUrls[i].split(',')[1], { base64: true });
    }
    if (dataUrl0) {
        if(dataUrl0.type == "image/svg+xml;charset=utf-8")
            zip.file('Legend Diagram.svg', dataUrl0);
        else
            zip.file('Legend Diagram.png', dataUrl0.split(',')[1], { base64: true });
    }    
    zip.file('Legend.LChS', LChS);
    zip.file('Legend.csv', csv);
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'legend.zip');
    setLoading(false);
  };

  useEffect(()=>{
    if(triggerFullExport){
      fullExport();
      setFullExport(false);
    }    
  },[triggerFullExport]);

  const toast = useRef(null);

  return (
    <>
    <Toast ref={toast} /> 
    <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}  style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <div style={{ height: 'fit-content', minHeight: '10%', maxHeight: '20%', overflow: 'auto', padding: 0, margin: 0 }}>
        <TabList className="guide-legendTabs p-0 m-0 react-tabs__tab-list">
            <Tab key="Full Legend"><p style={{ fontWeight: 'bold', padding: 0, margin: 0 }}>Legend</p></Tab>
            {ClassTabs.current.map((tab) => {
            return (
                <Tab key={tab.title}>{tab.title}</Tab>
            );
            })}        
        </TabList>
      </div>
      <div className="guide-legendDiagram" style={{ height: 'fit-content', minHeight: '80%', maxHeight: '83%', overflow: 'auto', padding: 0, margin: 0 }}>
        <TabPanel key="Full Legend">
            <MermaidChartRender chart={flowDefinition} legendValid={legendValid} setActiveLCElement={setActiveLCElement} index="Full Legend" />
        </TabPanel>
        {ClassTabs.current.map((tab) => {
          return (
              <TabPanel key={tab.title} className="flex p-0 m-0">              
                  <MermaidChartRender chart={tab.content} legendValid={legendValid} setActiveLCElement={setActiveLCElement} index={tab.title} />
              </TabPanel>
          );
        })}        
      </div>
    </Tabs>
    {loading && (
      <div className="loading-overlay">
        <div className="loading-spinner"></div>
      </div>
    )}
    </>
  );
};
