import { useState,useRef,useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { Controller, useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { Tree, TreeTogglerTemplateOptions } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { classNames } from 'primereact/utils';
import { Slider, SliderChangeEvent } from "primereact/slider";
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumber } from "primereact/inputnumber";
import { Chip } from 'primereact/chip';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import '../../css/TreeView.css';

export const GuideLegendCreation4 = (props:any) => {
     const {UUID,
            setRerenderTrigger,   
            setGuide3Visible,
            setGuide4Visible,
            blocks,
            characteristicLookUp,
            characteristics,
            elements,
            LC_HorizontalPatterns,
            LC_Strata,
            LC_Properties,
            stratumPropertyNumber,
            LC_Characteristics,
            stratumCharacteristicNumber,
            OptionsBus,
            options
        } = props;
    
    const toast = useRef(null);

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        getValues,
        setValue,
        watch,
        reset
    } = useForm({ });
    const getFormErrorMessage = (name:any) => {
        return errors[name] ? <small className="p-error">This field is required</small> : <small className="p-error">&nbsp;</small>;
    };
    const {
        register: register1,
        control: control1,
        formState: { errors:errors1 },
        handleSubmit: handleSubmit1,
        getValues: getValues1,
        setValue: setValue1,
        reset: reset1
    } = useForm({ });    
    const getFormErrorMessage1 = (name:any) => {
        return errors1[name] ? <small className="p-error">This field is required</small> : <small className="p-error">&nbsp;</small>;
    };

    const showProperty = () => {
        for (const [key, value] of Object.entries(getValues())) {
            toast.current.show({ severity: 'success', summary: 'Property Submitted', detail: key+": "+value});
        };
    };
    const showCharacteristic = () => {
        for (const [key, value] of Object.entries(getValues1())) {
            toast.current.show({ severity: 'success', summary: 'Characteristic Submitted', detail: key+": "+value});
        };
    };
    const [StratumPropertyID, setSPID] = useState(stratumPropertyNumber.current);
    const [stratumProperties, addStratumProperty] = useState(LC_Properties.current);
    const submitProperty = (data: any,event) => {
        event.preventDefault();
        let StratumID = (document.getElementById("stratumID") as HTMLInputElement).value;
        let BlockID = (document.getElementById("blockID") as HTMLInputElement).value;
        let BlockReference = (document.getElementById("blockReference") as HTMLInputElement).value;
        let formValues = {};
        Object.entries(getValues()).forEach((submission)=>{            
            if(typeof(submission[1]) === "object"){
                if(!Array.isArray(submission[1])){                    
                    if(submission[1]['label'] !== undefined){                        
                        submission = [submission[0],submission[1]['label']];                        
                    }                        
                }                
            }            
            formValues = {...formValues, [submission[0]]: submission[1]};
        });
        addStratumProperty([...(stratumProperties.filter(properties => ((properties.StratumID !== parseInt(StratumID)) || (properties.StratumID === parseInt(StratumID) && properties.BlockID !== parseInt(BlockID)))) || []), { StratumID: parseInt(StratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...formValues }]);
        showProperty();
        setSPID(StratumPropertyID+1);
        stratumPropertyNumber.current++;
        LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(StratumID)) || (properties.StratumID === parseInt(StratumID) && properties.BlockID !== parseInt(BlockID)))) || []), { StratumID: parseInt(StratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...formValues }];
        setRerenderTrigger('Element:'+parseInt(StratumID)+parseInt(BlockID));
        reset();

        let tempActiveStratum = activeStratum;
        let tempActiveBlock = activeBlock;
        const timer = setTimeout(() => {
            setActiveStratum(null);
            setActiveBlock(null);
        }, 100);
        const timer2 = setTimeout(() => {
            setActiveStratum(tempActiveStratum);
            setActiveBlock(tempActiveBlock);            
        }, 300);
    };
    const [StratumCharacteristicID, setCID] = useState(stratumCharacteristicNumber.current);
    const [stratumCharacteristics, addStratumCharacteristic] = useState(LC_Characteristics.current);
    const submitCharacteristic = (data: any,event) => {
        event.preventDefault();
        let StratumID = (document.getElementById("stratumID") as HTMLInputElement).value;
        let BlockID = (document.getElementById("blockID") as HTMLInputElement).value;
        let BlockReference = (document.getElementById("blockReference") as HTMLInputElement).value;
        let CharacteristicID = (document.getElementById("characteristicID") as HTMLInputElement).value;
        let CharacteristicReference = (document.getElementById("characteristicReference") as HTMLInputElement).value;
        let CharacteristicLabel = (document.getElementById("characteristicLabel") as HTMLInputElement).value;
        let formValues = {};
        Object.entries(getValues1()).forEach((submission)=>{            
            if(typeof(submission[1]) === "object"){
                if(!Array.isArray(submission[1])){                    
                    if(submission[1]['label'] !== undefined){                        
                        submission = [submission[0],submission[1]['label']];                        
                    }                        
                }                
            }            
            formValues = {...formValues, [submission[0]]: submission[1]};
        });
        addStratumCharacteristic([...(stratumCharacteristics.filter(characteristics => ((characteristics.StratumID !== parseInt(StratumID) || characteristics.BlockID !== parseInt(BlockID)) || (characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID) && characteristics.CharacteristicID !== parseInt(CharacteristicID)))) || []), { StratumID: parseInt(StratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, CharacteristicID: parseInt(CharacteristicID), CharacteristicReference: CharacteristicReference, CharacteristicLabel: CharacteristicLabel, ...formValues }]);
        showCharacteristic();
        setCID(StratumCharacteristicID+1);
        stratumCharacteristicNumber.current++;
        LC_Characteristics.current = [...(LC_Characteristics.current.filter(characteristics => ((characteristics.StratumID !== parseInt(StratumID) || characteristics.BlockID !== parseInt(BlockID)) || (characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID) && characteristics.CharacteristicID !== parseInt(CharacteristicID)))) || []), { StratumID: parseInt(StratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, CharacteristicID: parseInt(CharacteristicID), CharacteristicReference: CharacteristicReference, CharacteristicLabel: CharacteristicLabel, ...formValues }];
        setRerenderTrigger('Element:'+parseInt(StratumID)+parseInt(BlockID));
        reset1();

        let tempActiveStratum = activeStratum;
        let tempActiveBlock = activeBlock;
        let tempActiveCharacteristic = activeCharacteristic;
        const timer = setTimeout(() => {
            setActiveStratum(null);
            setActiveBlock(null);
            setActiveCharacteristic(null);
        }, 100);
        const timer2 = setTimeout(() => {
            setActiveStratum(tempActiveStratum);
            setActiveBlock(tempActiveBlock);
            setActiveCharacteristic(tempActiveCharacteristic);
        }, 300);
    };
    
    let patterns = [];
    let strata = [];
    Object.keys(LC_HorizontalPatterns?.current).forEach((key0) => {     
        let filterd_strata = LC_Strata?.current.filter( stratum => stratum.HPID === LC_HorizontalPatterns?.current[key0]["horizontal_pattern_id"] );
        if(filterd_strata !== undefined){            
            Object.keys(filterd_strata).forEach((key1) => {       
                strata = [...strata, { key: LC_HorizontalPatterns?.current[key0]["horizontal_pattern_id"]+"-"+filterd_strata[key1]["stratumID"], label: filterd_strata[key1]["name"], icon: 'pi pi-fw pi-minus' }
                        ];                  
            });
            patterns = [...patterns, {key: ""+LC_HorizontalPatterns?.current[key0]["horizontal_pattern_id"], label: LC_HorizontalPatterns?.current[key0]["name"], icon: 'pi pi-fw pi-bars', children: strata }];            
        }
        strata = [];
    });
    
    const togglerTemplate = (node: TreeNode, options: TreeTogglerTemplateOptions) => {
        if (!node) {
            return;
        }
        const expanded = options.expanded;
        const iconClassName = classNames('text-xs p-0 m-0 p-tree-toggler-icon pi pi-fw', {
            'text-xs p-0 m-0 pi-caret-right': !expanded,
            'text-xs p-0 m-0 pi-caret-down': expanded
        });
        return (            
            <button type="button" className="text-xs p-0 m-0 p-tree-toggler p-link" tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>            
        );
    };    
    const [displayElements,setDisplayElements] = useState(false);
    const [displayInstruction,setDisplayInstruction] = useState(true);
    const [SPIDBID,setSPIDBID] = useState<any>([]);
    const [activeStratum, setActiveStratum] = useState<any>(undefined);    
    const [activeStratumDetails,setActiveStratumDetails] = useState(undefined);
    const [activeBlock, setActiveBlock] = useState<any>(undefined);    
    const [activeBlockDetails,setActiveBlockDetails] = useState(undefined);
    const [displayCharacteristics,setdisplayCharacteristics] = useState([]);
    const [activeCharacteristic, setActiveCharacteristic] = useState<any>(null);    
    const [activeCharacteristicDetails,setActiveCharacteristicDetails] = useState(undefined);
    const [XteristicResets, setXteristicResets] = useState<any>();
    const [propertyResets, setPropertyResets] = useState<any>();
    const [disableXteristic, setDisableXteristic] = useState<boolean>(true);
    useEffect(()=>{
        reset1();
        if(typeof(activeCharacteristic) === 'number')
            setDisableXteristic(false);
        else
            setDisableXteristic(true);
        displayFormElements('','');
        let activCharacteristic = characteristics.current["LC_Characteristics"]?.filter( characteristic => characteristic.characteristic_id === activeCharacteristic);        
        let resetter = [];
        let display = [];
        let reconfig = [];
        if(activCharacteristic !== undefined && activCharacteristic[0] !== undefined){
            setActiveCharacteristicDetails(activCharacteristic[0]);
            let stratumKey = activeStratum?.split("-");
            if(stratumKey === undefined)
                stratumKey = 0;
            let blockKey = activeBlock?.split("-");
            if(blockKey === undefined)
                blockKey = 0;
            activCharacteristic[0].elements.map((element)=>{
                display.push('document.getElementById("guide_'+element['element_name']+'").style.display = "'+element['display_default']+'"');
                let prevValue = undefined;
                if(stratumCharacteristics.length > 0){
                    let relevantCharacteristic = stratumCharacteristics.filter(characteristics => characteristics.StratumID === parseInt(stratumKey[stratumKey.length-1]) && characteristics.BlockID === parseInt(blockKey[blockKey.length-1]) && characteristics.CharacteristicID === activeCharacteristic);                    
                    if(relevantCharacteristic[0] !== undefined){
                        prevValue = relevantCharacteristic[0][element['element_name']];
                        if(typeof(prevValue) === "object")
                            resetter.push('setValue1("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                        else if(typeof(prevValue) === "number")
                            resetter.push('setValue1("'+element['element_name']+'", '+prevValue+')');
                        else
                            resetter.push('setValue1("'+element['element_name']+'", "'+prevValue+'")');
                    }
                    else{
                        if(element['element_type'] === 'Range'){
                            prevValue = [element['element_rules']['min'],element['element_rules']['max']];
                            resetter.push('setValue1("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                        }
                        else if(element['element_type'] === "number"){
                            prevValue = element['element_rules']['min'];
                            resetter.push('setValue1("'+element['element_name']+'", '+prevValue+')');
                        }
                        else{
                            prevValue = "";
                            resetter.push('setValue1("'+element['element_name']+'", "'+prevValue+'")');
                        }
                    }
                    if(element['element_type'] == 'Dropdown' && prevValue != '')
                        reconfig.push('formReconfig_guide("'+element['element_rules']['options_name']+'","'+prevValue+'")');
                }
                else{
                    if(element['element_type'] === 'Range'){
                        prevValue = [element['element_rules']['min'],element['element_rules']['max']];
                        resetter.push('setValue1("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                    }
                    else if(element['element_type'] === "number"){
                        prevValue = element['element_rules']['min'];
                        resetter.push('setValue1("'+element['element_name']+'", '+prevValue+')');
                    }
                    else{
                        prevValue = "";
                        resetter.push('setValue1("'+element['element_name']+'", "'+prevValue+'")');
                    }
                }
            });
            setXteristicResets(resetter);
            displays_guide.current = display;
            reconfigs_guide.current = reconfig;
        }
        else
            setActiveCharacteristicDetails(undefined);
    },[activeCharacteristic]);

    const displays_guide = useRef<any>([]);
    const reconfigs_guide = useRef<any>([]);

    useEffect(()=>{
        setActiveCharacteristic(null);
        reset();
        
        if(activeBlock !== undefined && activeStratum !== undefined){
            setDisplayInstruction(false);
            setDisplayElements(true);
        }

        let stratumKey = activeStratum?.split("-");
        if(stratumKey === undefined)
         stratumKey = 0;

        if(stratumKey?.length > 1){
            let activStratum = LC_Strata.current.filter( stratum => stratum.stratumID === parseInt(stratumKey[stratumKey.length-1]) );            
            if(activStratum !== undefined)
                setActiveStratumDetails(activStratum[0]);
            else{
                setActiveStratumDetails(undefined);
                setDisplayInstruction(true);
                setDisplayElements(false);
            }
        }else{
            setActiveStratumDetails(undefined);
            setDisplayInstruction(true);
            setDisplayElements(false);
        }

        let blockKey = activeBlock?.split("-");
        if(blockKey !== undefined){
            let activBlock = blocks.current["LC_Block"]?.filter( block => block.block_id === parseInt(blockKey[blockKey.length-1]) );            
            let resetter = [];
            let display = [];
            let reconfig = [];
            if(activBlock.length > 0){
                setActiveBlockDetails(activBlock[0]);
                activBlock[0].elements.map((element)=>{
                    display.push('document.getElementById("guide_'+element['element_name']+'").style.display = "'+element['display_default']+'"');
                    let prevValue = undefined;
                    if(stratumProperties.length > 0){
                        let relevantProperty = stratumProperties.filter(property => property.StratumID === parseInt(stratumKey[stratumKey.length-1]) && property.BlockID === parseInt(blockKey[blockKey.length-1]));                        
                        if(relevantProperty[0] !== undefined){
                            prevValue = relevantProperty[0][element['element_name']];
                            if(typeof(prevValue) === "object")
                                resetter.push('setValue("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                            else if(typeof(prevValue) === "number")
                                resetter.push('setValue("'+element['element_name']+'", '+prevValue+')');
                            else
                                resetter.push('setValue("'+element['element_name']+'", "'+prevValue+'")');
                        }
                        else{
                            if(element['element_type'] === 'Range'){
                                prevValue = [element['element_rules']['min'],element['element_rules']['max']];
                                resetter.push('setValue("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                            }
                            else if(element['element_type'] === "number"){
                                prevValue = element['element_rules']['min'];
                                resetter.push('setValue("'+element['element_name']+'", '+prevValue+')');
                            }
                            else{
                                prevValue = "";
                                resetter.push('setValue("'+element['element_name']+'", "'+prevValue+'")');
                            }
                        } 
                        if(element['element_type'] == 'Dropdown' && prevValue != '')
                            reconfig.push('formReconfig_guide("'+element['element_rules']['options_name']+'","'+prevValue+'")');
                    }
                    else{
                        if(element['element_type'] === 'Range'){
                            prevValue = [element['element_rules']['min'],element['element_rules']['max']];
                            resetter.push('setValue("'+element['element_name']+'", ['+prevValue[0]+','+prevValue[1]+'])');
                        }
                        else if(element['element_type'] === "number"){
                            prevValue = element['element_rules']['min'];
                            resetter.push('setValue("'+element['element_name']+'", '+prevValue+')');
                        }
                        else{
                            prevValue = "";
                            resetter.push('setValue("'+element['element_name']+'", "'+prevValue+'")');
                        }
                    }
                });
                setPropertyResets(resetter);
                displays_guide.current = display;
                reconfigs_guide.current = reconfig;
            }
            else
                setActiveBlockDetails(undefined);            
            setSPIDBID([parseInt(stratumKey[stratumKey.length-1]),parseInt(blockKey[blockKey.length-1])]);

            let subDisplayCharacteristics = [];
            Object.keys(characteristicLookUp.current).forEach((key0) => {
                let _1_blocks = characteristicLookUp.current[key0].filter( selectedblock => selectedblock.block_id === parseInt(blockKey[blockKey.length-1] ));                
                Object.keys(_1_blocks).forEach((key1) => {
                    let _characteristics = characteristics.current["LC_Characteristics"]?.filter( characteristics => characteristics.characteristic_id === _1_blocks[key1]["characteristic_id"] );                    
                    Object.keys(_characteristics).forEach((key2) => {                        
                        subDisplayCharacteristics = ([...subDisplayCharacteristics, { label: _characteristics[key2]["characteristic_label"], name: _characteristics[key2]["characteristic_name"], value: _characteristics[key2]["characteristic_id"] }]);
                    });
                });
            });
            setdisplayCharacteristics(subDisplayCharacteristics);        
        }else{
            blockKey = 0;
            setActiveBlockDetails(undefined);
            setdisplayCharacteristics([]);
        }
        const timer = setTimeout(() => {
            reset();
            reset1();
        }, 500);
    },[activeStratum,activeBlock]);

    const propertyReset = ()=>{
        propertyResets?.map((formElement)=>{
            eval(formElement);            
        });
        formReconfigExe_guide();
    }
    const XteristicReset = ()=>{
        XteristicResets?.map((formElement)=>{
            eval(formElement);
        });
        formReconfigExe_guide();
    }

    const displayFormElements = (elements,form)=>{
        var ctlr = [];
        if(elements != undefined){
            Object.values(elements).forEach((element)=>{                
                let component = null;
                switch(element['element_type']) {
                case "Range":
                    component = <Controller
                                name={element['element_name']}                            
                                control={eval('control'+form)}
                                defaultValue={[element['element_rules']['min'],element['element_rules']['max']]}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1 mb-1" id={'guide_'+field.name}>
                                        <span className="p-inputgroup-addon text-xs p-2 pl-3 pr-3 m-0">{element['element_label']}:</span>
                                            <div className="card flex flex-column w-full gap-0">
                                            <Slider name={field.name} value={field.value as any} onChange={(e: SliderChangeEvent) => field.onChange(e.value as number[])} className="p-inputtext-sm text-xs p-0 m-0" range style={{width: '100%', alignSelf: 'center',verticalAlign: 'middle'}} min={element['element_rules']['min']} max={element['element_rules']['max']} required={element['element_rules']['required']} />
                                            <span className="text-xs p-0 m-0 mt-2" style={{ textAlign: 'center' }}>{field.value[0]} - {field.value[1]}</span>
                                            </div>
                                        <span className="p-inputgroup-addon text-xs p-2 m-0">{element['element_rules']['symbol']}</span>
                                    </div>
                                    )}
                                />;
                    break;
                case "Dropdown":
                    component = <Controller
                                name={element['element_name']}
                                control={eval('control'+form)}
                                rules={{ required:element['element_rules']['required'] }}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1 mb-1" id={'guide_'+field.name}>
                                        <span className="p-inputgroup-addon text-s p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <Dropdown inputId={field.name} name={field.name} value={field.value} onChange={(e: DropdownChangeEvent) => {field.onChange(e.value);formReconfig_guide(element['element_rules']['options_name'],e.value)}} inputRef={field.ref} options={eval(element['element_rules']['list'])} optionLabel="label" placeholder={"Select a "+element['element_label']} className="p-inputtext-sm text-xs p-0 m-0" editable required={element['element_rules']['required']} tooltip={element['element_label']} tooltipOptions={{ event: 'both' }} />
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">{element['element_rules']['symbol']}</span>
                                    </div>
                                    )}
                                />;
                    break;
                case "Number":
                    component = <Controller
                                name={element['element_name']}
                                control={eval('control'+form)}
                                defaultValue={element['element_rules']['min']}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1 mb-1" id={'guide_'+field.name}>
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <InputNumber name={field.name} value={field.value} className="p-inputtext-sm text-xs p-1 m-0" onValueChange={(e) => field.onChange(e.target.value)} showButtons buttonLayout="horizontal" style={{ width: '100%' }} min={element['element_rules']['min']} max={element['element_rules']['max']} placeholder={element['element_label']} required={element['element_rules']['required']} tooltip={element['element_label']} tooltipOptions={{ event: 'both' }} />
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">{element['element_rules']['symbol']}</span>
                                    </div>
                                    )}
                                />;
                    break;
                default:
                    component =<>
                                <div className="p-inputgroup flex-1 mb-1" id={'guide_'+element['element_name']}>
                                    <span className="p-inputgroup-addon text-xs p-0 m-0">
                                        <i className="pi pi-pencil"></i>
                                    </span>                                    
                                    <InputText {...eval('register'+form+'("'+element['element_name']+'")')} className="p-inputtext-sm text-xs p-2 m-0" style={{ width: '100%' }} placeholder={element['element_label']} required={element['element_rules']['required']} tooltip={element['element_label']} tooltipOptions={{ event: 'both' }} />
                                    <span className="p-inputgroup-addon text-xs p-0 m-0">{element['element_rules']['symbol']}</span>                                
                                </div>
                            </>;
                }
                ctlr.push(component);
            });
        }
        return (<>{ctlr}</>);
    }

    const processElements = ()=> { 
        const propertyCheck = LC_Strata?.current.filter(({ stratumID: id1 }) => !stratumProperties?.some(({ StratumID: id2 }) => id2 === id1));
        
        let SP = stratumProperties?.length;
        let chr = stratumCharacteristics?.length;
        
        if(SP > 0 && propertyCheck.length === 0){            
            toast.current.show({ severity: 'success', summary: 'Elements Saved', detail: "Properties: "+SP+" Characteristics: "+chr });
            const timer = setTimeout(() => {                
                setGuide4Visible(false);
                setRerenderTrigger('Elements');
            }, 1000);           
        }
        else{
            toast.current.show({ severity: 'error', summary: 'Elements Error', detail: "Properties: "+SP+" Characteristics: "+chr, life: 60000 });
            SP === 0? toast.current.show({ severity: 'error', summary: 'Element & Property Error', detail: "Stratum Elements and Properties are required.", life: 60000 }) : toast.current.show({ severity: 'error', summary: 'Property Error', detail: "Properties for "+propertyCheck?.map((property)=>(property.name))+" are undefined.", life: 60000 });
        }        
    }

    const [confirmPropertyDelete, setConfirmPropertyDelete] = useState<boolean>(false);    
    const [confirmCharacteristicDelete, setConfirmCharacteristicDelete] = useState<boolean>(false);
    const [StratumID,setStratumID] = useState<number>(null);
    const [BlockID,setBlockID] = useState<number>(null);
    const promptPropertyDelete = (StratumID,BlockID)=>{       
        setStratumID(StratumID);
        setBlockID(BlockID);
        setConfirmPropertyDelete(true);
    }    
    const [CharacteristicID,setCharacteristicID] = useState<number>(null);
    const promptCharacteristicDelete = (StratumID,BlockID,CharacteristicID)=>{        
        setStratumID(StratumID);
        setBlockID(BlockID);
        setCharacteristicID(CharacteristicID);        
        setConfirmCharacteristicDelete(true);
    }    
    const acceptPropertyDelete = (StratumID,BlockID) => {
        let deletd_Element = blocks.current["LC_Block"].filter(blocks => blocks.block_id === parseInt(BlockID));       
        deleteProperty(StratumID,BlockID);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Element '+deletd_Element[0].block_label+' deleted! Please click on a different Element to refresh.', life: 3000 });
    }    
    const acceptCharacteristicDelete = (StratumID,BlockID,CharacteristicID) => {
        let deletd_Characteristic = LC_Characteristics.current.filter(characteristics => characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID) && characteristics.CharacteristicID === parseInt(CharacteristicID));        
        deleteCharacteristic(StratumID,BlockID,CharacteristicID);
        toast.current?.show({ severity: 'warn', summary: 'Confirmed', detail: 'Element '+deletd_Characteristic[0].CharacteristicLabel+' deleted! Please click on a different Element to refresh.', life: 3000 });
    }
    const reject = () => {
        toast.current?.show({ severity: 'info', summary: 'Cancelled', detail: 'Action Cancelled. Please click on a different Element to refresh.', life: 3000 });
    }

    const deleteProperty = (StratumID,BlockID)=>{        
        let undeletd_properties = stratumProperties.filter(properties => ((properties.StratumID !== parseInt(StratumID)) || (properties.StratumID === parseInt(StratumID) && properties.BlockID !== parseInt(BlockID))));
        let deletd_characteristics = stratumCharacteristics.filter(characteristics => characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID));        
        if(deletd_characteristics !== undefined){            
            deletd_characteristics.map((deletd_characteristic)=>{
                deleteCharacteristic(deletd_characteristic.StratumID,deletd_characteristic.BlockID,deletd_characteristic.CharacteristicID);
            });
        }
        addStratumProperty([...(undeletd_properties || [])]);        
        LC_Properties.current = [...(undeletd_properties || [])];
        stratumPropertyNumber.current = undeletd_properties.length+1;
    }
    const deleteCharacteristic = (StratumID,BlockID,CharacteristicID)=>{        
        let undeletd_characteristics = stratumCharacteristics.filter(characteristics => ((characteristics.StratumID !== parseInt(StratumID) || characteristics.BlockID !== parseInt(BlockID)) || (characteristics.StratumID === parseInt(StratumID) && characteristics.BlockID === parseInt(BlockID) && characteristics.CharacteristicID !== parseInt(CharacteristicID))));        
        addStratumCharacteristic([...(undeletd_characteristics || [])]);
        LC_Characteristics.current = [...(undeletd_characteristics || [])];
        stratumCharacteristicNumber.current = undeletd_characteristics.length+1;        
    }

    const formDefault_guide = ()=>{        
        if(displays_guide.current.length > 0){
            displays_guide.current?.map((displayCondition)=>{
                if(displayCondition){
                    const timer = setTimeout(() => {
                        eval(displayCondition);
                    }, 20);
                }
            });
        }
        displays_guide.current = [];
    }
    const formReconfig_guide = (element,value)=>{        
        let option = options.current["LC_Options"]?.filter( selectedOptions => selectedOptions.option_name === element );
        let optionDetails = option[0].options.find(o => o.label === value.label);
        if(optionDetails == undefined)
            optionDetails = option[0].options.find(o => o.label === value);        
        if(optionDetails!=undefined){
            let show = optionDetails['show']?.split(',');
            show?.map((element)=>{                
                const timer = setTimeout(() => {
                    eval('document.getElementById("guide_'+element.trim()+'").style.display = ""');
                }, 20);
            });            
            let hide = optionDetails['hide']?.split(',');
            hide?.map((element)=>{            
                const timer = setTimeout(() => {                
                    eval('document.getElementById("guide_'+element.trim()+'").style.display = "none"');
                }, 20);
            });
        }
    }
    const formReconfigExe_guide = ()=>{
        if(reconfigs_guide.current?.length > 0){
            reconfigs_guide.current?.map((displayCondition)=>{                
                if(displayCondition){
                    const timer = setTimeout(() => {
                        eval(displayCondition);
                    }, 20);
                }
            });
        }
        //reconfigs.current = [];
    }

    return (
        <div className="card flex flex-column justify-content-center">
            <div className="card flex flex-row justify-content-center">
                <div className="card flex flex-column justify-content-center align-self-start" style={{ width: '40%' }}>
                    <Card title="Step4: Elements" subTitle="Add Element Properties and Characteristics" className="w-full" style={{ background: '#eee' }}>
                        <p className="m-0">
                            Add all the elements in your class.  Set the values of the Element Properties and Characteristics
                        </p>
                    </Card>
                    <Divider />
                    <div className="card w-full" style={{ maxHeight: '800px', overflow: 'auto' }}>
                        <h3>Strata</h3>
                        <Tree value={patterns} selectionMode="single" selectionKeys={activeStratum} onSelectionChange={(e) => setActiveStratum(e.value)} togglerTemplate={togglerTemplate} className=".p-tree-horizontal p-0 m-0 w-full flex-grow text-xs" style={{ width: '100%' }} />
                        <Divider />
                        <h3>Elements</h3>
                        <Tree value={elements} selectionMode="single" selectionKeys={activeBlock} onSelectionChange={(e) => setActiveBlock(e.value)} filter filterMode="strict" filterPlaceholder="Search LCML Elements" togglerTemplate={togglerTemplate} className=".p-tree-horizontal p-0 m-0 w-full flex-grow text-xs" style={{ width: '100%' }} />
                    </div>
                        
                </div>

                <Divider layout="vertical" />
                
                <div className="card flex flex-column" style={{ width: '60%' }}>
                    { displayInstruction &&
                        <Card title="Pick Stratum and Element" subTitle="On the left menus, select the relevant stratum and element" className="w-full" style={{ width: '50%', background: '#eee', marginTop: '20vh' }}>
                            <i className="pi pi-arrow-left" style={{ fontSize: '2.5rem' }}></i><i className="pi pi-spin pi-cog" style={{ fontSize: '2.5rem' }}></i>
                        </Card>
                    }
                    { displayElements &&
                        <Card title={activeStratumDetails?.name+" > "+activeBlockDetails?.block_label} subTitle={"Set Element Properties & Characteristics"} className="w-full" style={{ width: '50%' }}>                        
                            <div className="card flex w-full">                
                                <Toast ref={toast} />
                                <ConfirmDialog visible={confirmPropertyDelete} onHide={() => setConfirmPropertyDelete(false)} message="Are you sure you want to delete the Property? This will delete the Element and Characteristics associated with it." header="Delete Property & Element Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptPropertyDelete(StratumID,BlockID)} reject={reject} />
                                <div className="card flex flex-column justify-content-center w-full" style={{ padding: 0, margin: 0 }}>
                                    <p className="text-xs justify-content-center" style={{ backgroundColor: 'var(--highlight-bg)' }}><b><u style={{textAlign: "center"}}>Property</u><br/><i className="pi pi-info-circle" style={{ color: 'slateblue' }}></i>{activeBlockDetails?.block_label}</b><br/>{activeBlockDetails?.block_description}</p>
                                    <div className="card flex flex-row">                               
                                        {stratumProperties?.map((Properties)=>{
                                            if(JSON.stringify(SPIDBID) === JSON.stringify([parseInt(Properties?.StratumID),parseInt(Properties?.BlockID)])){return(<Chip key={parseInt(Properties?.StratumID)+"-"+parseInt(Properties?.BlockID)} className="text-xs green" label={activeStratumDetails?.name+" > "+activeBlockDetails?.block_label} removable onRemove={()=>promptPropertyDelete(parseInt(Properties?.StratumID),parseInt(Properties?.BlockID))} />)}
                                        })}                                    
                                    </div> 
                                    <form id="Properties" name="Properties" onSubmit={handleSubmit(submitProperty)} className="flex flex-column justify-contents-center">                                                     
                                    <div className="w-full card justify-content-center gap-3">
                                        <Controller
                                        name={"stratumID"}                                 
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeStratumDetails?.stratumID} />
                                            )}
                                        />
                                        <Controller
                                        name={"blockID"}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeBlockDetails?.block_id} />
                                            )}
                                        />
                                        <Controller
                                        name={"blockReference"}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeBlockDetails?.block_reference} />
                                            )}
                                        />
                                        {
                                            <>
                                                {displayFormElements(activeBlockDetails?.elements,'')}
                                                {propertyReset()}
                                                {formDefault_guide()}
                                            </>
                                        }                                        
                                    </div>
                                    <div className="card mt-1" style={{ textAlign: 'right' }} >                        
                                        <Button label={"Add "+activeStratumDetails?.name+" > "+activeBlockDetails?.block_label+" Property"} icon="pi pi-save" type="submit" size="small" iconPos="right" className="align-self-end"  style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} />
                                    </div>
                                    </form>
                                </div>                       
                            </div>

                            <Divider />

                            <div className="card flex w-full">                
                                <Toast ref={toast} />
                                <ConfirmDialog visible={confirmCharacteristicDelete} onHide={() => setConfirmCharacteristicDelete(false)} message="Are you sure you want to delete the Characteristic?" header="Delete Characteristic Confirmation" icon="pi pi-exclamation-triangle" acceptClassName='p-button-danger' accept={()=>acceptCharacteristicDelete(StratumID,BlockID,CharacteristicID)} reject={reject} />
                                <div className="card flex flex-column justify-content-center w-full" style={{ padding: 0, margin: 0 }}>                                
                                    <p className="text-xs justify-content-center" style={{ backgroundColor: 'var(--highlight-bg)' }}><b><u>Characteristic</u><br/><i className="pi pi-info-circle" style={{ color: 'slateblue' }}></i>{activeBlockDetails?.block_label}</b><br/>{activeBlockDetails?.block_description}</p>
                                    <div className="card flex flex-row">
                                        {stratumCharacteristics?.map((Characteristics)=>{                                                                                        
                                            if(JSON.stringify(SPIDBID) === JSON.stringify([parseInt(Characteristics.StratumID),parseInt(Characteristics.BlockID)])){return(<Chip key={parseInt(Characteristics.StratumID)+"-"+parseInt(Characteristics.BlockID)+"-"+parseInt(Characteristics.CharacteristicID)} className="text-xs" label={activeStratumDetails?.name+" > "+activeBlockDetails?.block_label+" > "+Characteristics?.CharacteristicLabel} removable onRemove={()=>promptCharacteristicDelete(parseInt(Characteristics?.StratumID),parseInt(Characteristics?.BlockID),parseInt(Characteristics?.CharacteristicID))} />);}
                                        })}                                    
                                    </div> 
                                    <form id="Characteristics" name="Characteristics" onSubmit={handleSubmit1(submitCharacteristic)} className="flex flex-column justify-contents-center">                                                     
                                    <div className="w-full card justify-content-center gap-3">
                                        <Controller
                                        name={"stratumID"}                                 
                                        control={control1}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeStratumDetails?.stratumID} />
                                            )}
                                        />
                                        <Controller
                                        name={"blockID"}                                 
                                        control={control1}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeBlockDetails?.block_id} />
                                            )}
                                        />
                                        <Controller
                                        name={"blockReference"}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeBlockDetails?.block_reference} />
                                            )}
                                        />
                                        <Controller
                                        name={"characteristicID"}                                 
                                        control={control1}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeCharacteristicDetails?.characteristic_id} />
                                            )}
                                        />
                                        <Controller
                                        name={"characteristicReference"}                                 
                                        control={control1}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeCharacteristicDetails?.characteristic_reference} />
                                            )}
                                        />
                                        <Controller
                                        name={"characteristicLabel"}                                 
                                        control={control1}
                                        render={({ field, fieldState }) => (
                                            <input type="hidden" id={field.name} name={field.name} value={activeCharacteristicDetails?.characteristic_label} />
                                            )}
                                        />                                    
                                        <ListBox listStyle={{ maxHeight: '150px' }} value={activeCharacteristic} onChange={(e: ListBoxChangeEvent) => setActiveCharacteristic(e.value)} options={displayCharacteristics} optionLabel="label" className="w-full text-xs" listClassName="text-xs" filter />
                                        {
                                            <>
                                                {displayFormElements(activeCharacteristicDetails?.elements,1)}
                                                {XteristicReset()}                                                
                                            </>
                                        }
                                    </div>
                                    <div className="card mt-1" style={{ textAlign: 'right' }} >                        
                                        <Button label={"Add "+activeStratumDetails?.name+" > "+activeBlockDetails?.block_label+" Characteristic"} icon="pi pi-save" type="submit" size="small" iconPos="right" className="align-self-end"  style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }} disabled={disableXteristic} />
                                    </div>
                                    </form>
                                </div>                       
                            </div>
                        </Card>
                    }
                </div>                
            </div>
            <div className="card mt-3" style={{ textAlign: 'right' }} >
                <Button label="Back" icon="pi pi-arrow-left" onClick={() => {setGuide3Visible(true);setGuide4Visible(false);setRerenderTrigger('Elements');}} text size="small" />
                <Button label="Finish" onClick={() => processElements()} size="small" className="align-self-end" disabled={displayInstruction} />
            </div>
        </div>
    );
}