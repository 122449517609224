import { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Tree, TreeTogglerTemplateOptions,TreeNodeDoubleClickEvent } from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import { Toast } from 'primereact/toast';

import '../css/TreeView.css';

export const TreeView = (props)=> {
    const {UUID,
            legend,
            searchObj,
            rerenderTrigger,
            setRerenderTrigger,
            blocks,
            elements,
            LC_Legend,
            LC_Class,
            LC_ClassCharacteristics,          
            LC_HorizontalPatterns,
            LC_Strata,
            horizontalPatternNumber,
            strataNumber,
            LC_Properties,
            stratumPropertyNumber,            
            activeLCElement,
            setActiveLCElement
        } = props;
    const toast = useRef(null);

    const constructLCBlock = (nodeKey)=>{        
        let blockKey = nodeKey?.split("-");
        let BlockID = blockKey[blockKey.length-1];
        let filterd_Properties = blocks?.current["LC_Block"].filter( block => block.block_id === parseInt(BlockID) );
        let BlockReference = filterd_Properties[0].block_reference;
        let propertyDataElements = [];
        Object.entries(filterd_Properties[0]["elements"]).forEach((propertyElement)=>{
            if(propertyElement[1]["element_name"] !== "elementPresenceType"){
                if(propertyElement[1]["element_type"] === "Number")
                    propertyDataElements = {...propertyDataElements, [propertyElement[1]["element_name"]]: propertyElement[1]["element_rules"]['min']};
                else if(propertyElement[1]["element_type"] === "Range")
                    propertyDataElements = {...propertyDataElements, [propertyElement[1]["element_name"]]: [propertyElement[1]["element_rules"]['min'],propertyElement[1]["element_rules"]['max']]};
                else
                    propertyDataElements = {...propertyDataElements, [propertyElement[1]["element_name"]]: ""};
            }
            else
                propertyDataElements = {...propertyDataElements, [propertyElement[1]["element_name"]]: "Fixed"};
        });
        
        let filterd_classes = LC_Class?.current.filter( clss => clss.legend_id === LC_Legend?.current["id"] );        
        if(filterd_classes.length !== 0){
            if(filterd_classes.length === 1){
                if(LC_HorizontalPatterns.current.length === 0){
                    LC_HorizontalPatterns.current = [{ class_id: filterd_classes[0].class_id, horizontal_pattern_id: horizontalPatternNumber.current, name: "Horizontal Pattern "+horizontalPatternNumber.current, description: "Class Horizontal Pattern", cover: [0,100], occurrence: [0,100], type: null  }];
                    LC_Strata.current = [{ HPID: horizontalPatternNumber.current, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern "+horizontalPatternNumber.current+" Stratum", presenceType: "Fixed", on_top: null }];
                    LC_Properties.current = [{ StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                    horizontalPatternNumber.current++;
                    strataNumber.current++;
                    stratumPropertyNumber.current++;
                    setRerenderTrigger('New Horizontal Pattern'+stratumPropertyNumber.current);
                }
                else{
                    let elementKeys = [];
                    if (typeof activeLCElement === 'string' || activeLCElement instanceof String)                    
                        elementKeys = activeLCElement?.split("|");
                    if(LC_Strata.current.length === 0 && elementKeys.length !== 5){                        
                        if(LC_HorizontalPatterns.current.length === 1){
                            LC_Strata.current = [{ HPID: horizontalPatternNumber.current-1, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern "+horizontalPatternNumber.current+" Stratum", presenceType: "Fixed", on_top: null }];
                            LC_Properties.current = [{ StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                            strataNumber.current++;
                            stratumPropertyNumber.current++;
                            setRerenderTrigger('New Stratum'+stratumPropertyNumber.current);
                        }
                        else
                            toast.current.show({ severity: 'error', summary: 'Multiple locations available', detail: 'Select the Horizontal Pattern and add the Stratum for the element to be inserted.' });
                    }
                    else{
                        let activeStratumID = null;                        
                        if(activeLCElement){
                            let elementKey = activeLCElement?.split("|");                            
                            if(elementKey.length === 5){
                                let HPID = parseInt(elementKey[elementKey.length-1]);
                                let Strata = LC_Strata.current.filter(stratum => stratum.HPID === HPID);
                                if(Strata.length > 0){
                                    if(Strata.length === 1){
                                        activeStratumID = Strata[0].stratumID;
                                        LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                        stratumPropertyNumber.current++;
                                        setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                                    }
                                    else
                                        toast.current.show({ severity: 'error', summary: 'Multiple locations available', detail: 'Select the location for the element to be inserted.' });
                                }
                                else{
                                    LC_Strata.current = [...LC_Strata.current, { HPID: HPID, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern Stratum", presenceType: "Fixed", on_top: null }];
                                    LC_Properties.current = [...LC_Properties.current, { StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                    strataNumber.current++;
                                    stratumPropertyNumber.current++;
                                    setRerenderTrigger('New Stratum'+strataNumber.current);
                                }
                            }
                            else if(elementKey.length === 6){
                                activeStratumID = elementKey[elementKey.length-1];
                                LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                stratumPropertyNumber.current++;
                                setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                            }
                            else if(elementKey.length === 7){                                
                                let Strata = LC_Strata.current.filter(stratum => stratum.HPID === parseInt(elementKey[elementKey.length-2]))[0];                                
                                if(Strata !== undefined){
                                    activeStratumID = Strata.stratumID;
                                    LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                    stratumPropertyNumber.current++;
                                    setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                                }
                                else
                                    toast.current.show({ severity: 'error', summary: 'No Active Stratum', detail: 'Select the Stratum for the element to be inserted.' });
                            }
                            else{
                                let filterd_HPs = LC_HorizontalPatterns.current.filter( HP => HP.class_id === parseInt(elementKey[2]) );                                    
                                if(filterd_HPs.length <= 1){                                        
                                    if(filterd_HPs[0] !== undefined){
                                        let HPatternID = filterd_HPs[0]['horizontal_pattern_id'];
                                        let Strata = LC_Strata.current.filter(stratum => stratum.HPID === HPatternID)[0];                                            
                                        if(Strata !== undefined)
                                            activeStratumID = Strata.stratumID;
                                        else{
                                            activeStratumID = strataNumber.current;
                                            LC_Strata.current = [...LC_Strata.current, { HPID: HPatternID, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern Stratum", presenceType: "Fixed", on_top: null }];
                                            strataNumber.current++;
                                        }
                                        LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                        stratumPropertyNumber.current++;
                                        setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                                    }
                                    else{
                                        LC_HorizontalPatterns.current = [...LC_HorizontalPatterns.current, { class_id: parseInt(elementKey[2]), horizontal_pattern_id: horizontalPatternNumber.current, name: "Horizontal Pattern "+horizontalPatternNumber.current, description: "Class Horizontal Pattern", cover: [0,100], occurrence: [0,100], type: null  }];
                                        LC_Strata.current = [...LC_Strata.current, { HPID: horizontalPatternNumber.current, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern "+horizontalPatternNumber.current+" Stratum", presenceType: "Fixed", on_top: null }];
                                        LC_Properties.current = [...LC_Properties.current, { StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                        horizontalPatternNumber.current++;
                                        strataNumber.current++;
                                        stratumPropertyNumber.current++;
                                        setRerenderTrigger('New Horizontal Pattern'+stratumPropertyNumber.current);
                                    }
                                }
                                else
                                    toast.current.show({ severity: 'error', summary: 'Multiple locations available', detail: 'Select the location for the element to be inserted.' });
                            }
                        }
                        else{
                            if(LC_HorizontalPatterns.current.length === 1 && LC_Strata.current.length === 0){
                                LC_Strata.current = [{ HPID: horizontalPatternNumber.current, stratumID: strataNumber.current, name: "Stratum 1", description: "Horizontal Pattern 1 Stratum", presenceType: "Fixed", on_top: null }];
                                LC_Properties.current = [{ StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                strataNumber.current++;
                                stratumPropertyNumber.current++;
                                setRerenderTrigger('New Stratum'+stratumPropertyNumber.current);
                            }
                            else if(LC_HorizontalPatterns.current.length === 1 && LC_Strata.current.length === 1){
                                let HPatternID = LC_HorizontalPatterns.current[0]['horizontal_pattern_id'];
                                let Strata = LC_Strata.current.filter(stratum => stratum.HPID === HPatternID)[0];
                                activeStratumID = Strata.stratumID;
                                LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                stratumPropertyNumber.current++;
                                setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                            }
                            else
                                toast.current.show({ severity: 'error', summary: 'No Active Stratum', detail: 'Select the location for the element to be inserted.' });
                        }
                    }
                }
            }
            else{
                let activeStratumID = null;
                if(activeLCElement){
                    let elementKey = activeLCElement?.split("|");                    
                    if(elementKey.length === 5){
                        let HPID = parseInt(elementKey[elementKey.length-1]);
                        let Strata = LC_Strata.current.filter(stratum => stratum.HPID === HPID)[0];
                        if(Strata !== undefined){
                            activeStratumID = Strata.stratumID;
                            LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                            stratumPropertyNumber.current++;
                            setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                        }
                        else{
                            LC_Strata.current = [...LC_Strata.current, { HPID: HPID, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern Stratum", presenceType: "Fixed", on_top: null }];
                            LC_Properties.current = [...LC_Properties.current, { StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                            strataNumber.current++;
                            stratumPropertyNumber.current++;
                            setRerenderTrigger('New Stratum'+stratumPropertyNumber.current);
                        }
                    }
                    else if(elementKey.length === 6){
                        activeStratumID = elementKey[elementKey.length-1];
                        LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                        stratumPropertyNumber.current++;
                        setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                    }
                    else if(elementKey.length === 7){                                
                        let Strata = LC_Strata.current.filter(stratum => stratum.HPID === parseInt(elementKey[elementKey.length-2]))[0];                                
                        if(Strata !== undefined){
                            activeStratumID = Strata.stratumID;
                            LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                            stratumPropertyNumber.current++;
                            setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                        }
                        else
                            toast.current.show({ severity: 'error', summary: 'No Active Stratum', detail: 'Select the Stratum for the element to be inserted.' });
                    }
                    else if(elementKey.length >= 3){
                        let filterd_HPs = LC_HorizontalPatterns.current.filter( HP => HP.class_id === parseInt(elementKey[2]) );
                        if(filterd_HPs.length <= 1){
                            if(filterd_HPs[0] !== undefined){
                                let HPatternID = filterd_HPs[0]['horizontal_pattern_id'];
                                let Strata = LC_Strata.current.filter(stratum => stratum.HPID === HPatternID)[0];
                                if(Strata !== undefined)
                                    activeStratumID = Strata.stratumID;
                                else{
                                    activeStratumID = strataNumber.current;
                                    LC_Strata.current = [...LC_Strata.current, { HPID: HPatternID, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern Stratum", presenceType: "Fixed", on_top: null }];
                                    strataNumber.current++;
                                }
                                LC_Properties.current = [...(LC_Properties.current.filter(properties => ((properties.StratumID !== parseInt(activeStratumID)) || (properties.StratumID === parseInt(activeStratumID) && properties.BlockID !== parseInt(BlockID)))) || []), {StratumID: parseInt(activeStratumID), BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                stratumPropertyNumber.current++;
                                setRerenderTrigger('New Property'+stratumPropertyNumber.current);
                            }
                            else{
                                LC_HorizontalPatterns.current = [...LC_HorizontalPatterns.current, { class_id: parseInt(elementKey[2]), horizontal_pattern_id: horizontalPatternNumber.current, name: "Horizontal Pattern "+horizontalPatternNumber.current, description: "Class Horizontal Pattern", cover: [0,100], occurrence: [0,100], type: null  }];
                                LC_Strata.current = [...LC_Strata.current, { HPID: horizontalPatternNumber.current, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern "+horizontalPatternNumber.current+" Stratum", presenceType: "Fixed", on_top: null }];
                                LC_Properties.current = [...LC_Properties.current, { StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference, ...propertyDataElements }];
                                horizontalPatternNumber.current++;
                                strataNumber.current++;
                                stratumPropertyNumber.current++;
                                setRerenderTrigger('New Horizontal Pattern'+stratumPropertyNumber.current);
                            }
                        }
                        else
                            toast.current.show({ severity: 'error', summary: 'Multiple locations available', detail: 'Select the location for the element to be inserted.' });
                    }
                    else                        
                        toast.current.show({ severity: 'error', summary: 'No Active Stratum', detail: 'Select or create the Horizontal Pattern and Stratum for the element to be inserted.' });
                }
                else
                    toast.current.show({ severity: 'error', summary: 'No Active Stratum', detail: 'Select or create the Horizontal Pattern and Stratum for the element to be inserted.' });
            }
        }
        else
            toast.current.show({ severity: 'error', summary: 'No Classes', detail: 'Insert a Legend Class, Horizontal Pattern and Stratum for the element to be inserted to.' });
    }

    const [displayClasses,setDisplayClasses]=useState<any>([]);
    useEffect(() => {
        let classes = [];        
        let filterd_classes = LC_Class?.current.filter( clss => clss.legend_id === parseInt(LC_Legend?.current["id"]) );        
        if(filterd_classes !== undefined){
            Object.keys(filterd_classes).forEach((key0) => {                
                let class_id = filterd_classes[key0].class_id;                
                let CC_children = [];
                if(LC_ClassCharacteristics.current[class_id] !== undefined){
                    let treeKey = 1;
                    Object.entries(LC_ClassCharacteristics.current[class_id]).forEach((ClassXteristic)=>{
                        let characteristicTemplate = searchObj(legend["LC_ClassCharacteristics"],ClassXteristic[0]);                        
                        let CC_elements = [];
                        let elementKey = 1;
                        Object.entries(ClassXteristic[1]).forEach((Element)=>{
                            if(Element[0] !== ClassXteristic[0]+'-name' && Element[0] !== ClassXteristic[0]+'-description'){                            
                                let elementTemplate = searchObj(characteristicTemplate[0].elements,Element[0]);
                                if(elementTemplate[0] !== undefined){
                                    //CC_elements.push({ key: LC_ClassCharacteristics.current.id+'|'+treeKey+'|'+ClassXteristic[0]+'|'+elementKey, label: elementTemplate[0].element_label+': '+Element[1]+elementTemplate[0].element_rules.symbol, icon: 'text-xs p-0 m-1 pi pi-fw pi-tag'});
                                    CC_elements.push({ key: treeKey+'|'+elementKey+'|'+class_id+'|'+ClassXteristic[0], label: elementTemplate[0].element_label+': '+Element[1]+elementTemplate[0].element_rules.symbol, icon: 'text-xs p-0 m-1 pi pi-fw pi-tag'});
                                }
                                elementKey++;
                            }                        
                        });
                        if(characteristicTemplate[0] !== undefined){
                            CC_children.push({ 
                                key: 'A|'+LC_Legend.current.id+'|'+class_id+'|'+ClassXteristic[0],
                                label: characteristicTemplate[0]['characteristic_label']+': '+eval('ClassXteristic[1]["'+ClassXteristic[0]+'-name"]'),
                                icon: 'text-xs p-0 m-1 '+characteristicTemplate[0]['characteristic_icon'],
                                children: [...CC_elements]
                            });
                        }
                        treeKey++;
                    });
                }

                let filterd_HPs = LC_HorizontalPatterns?.current.filter( HP => HP.class_id === class_id );
                let patterns = [];
                let strata = [];
                let elementals = [];
                if(filterd_HPs !== undefined){
                    Object.keys(filterd_HPs).forEach((key0) => {
                        let filterd_strata = LC_Strata?.current.filter( stratum => stratum.HPID === filterd_HPs[key0]["horizontal_pattern_id"] );
                        if(filterd_strata !== undefined){            
                            Object.keys(filterd_strata).forEach((key1) => {
                                let filterd_elements = LC_Properties?.current.filter( properties => properties.StratumID === filterd_strata[key1]["stratumID"] );
                                Object.keys(filterd_elements).forEach((key2) => {
                                    let filterd_blocks = blocks?.current["LC_Block"].filter( block => block.block_id === filterd_elements[key2]["BlockID"] );
                                    if(filterd_blocks !== undefined)
                                        elementals = [...elementals, { key: 'A|'+LC_Legend.current.id+'|'+class_id+'|1|'+filterd_HPs[key0]["horizontal_pattern_id"]+"|"+filterd_strata[key1]["stratumID"]+'|'+filterd_blocks[0]["block_id"], label: filterd_blocks[0]["block_label"], icon: filterd_blocks[0]["block_icon"] }];                      
                                });
                                strata = [...strata, { key: 'A|'+LC_Legend.current.id+'|'+class_id+'|1|'+filterd_HPs[key0]["horizontal_pattern_id"]+"|"+filterd_strata[key1]["stratumID"], label: filterd_strata[key1]["name"], icon: 'pi pi-fw pi-minus', children: elementals }];
                                elementals = [];                              
                            });                
                            patterns = [...patterns, {key: 'A|'+LC_Legend.current.id+'|'+class_id+'|1|'+filterd_HPs[key0]["horizontal_pattern_id"],label: filterd_HPs[key0]["name"], icon: 'pi pi-fw pi-bars', children: strata }];
                        }
                        strata = [];
                    });
                }

                classes = [...classes, 
                                    { 
                                        key: 'A|'+LC_Legend.current.id+'|'+class_id,
                                        label: filterd_classes[key0]["class_name"],
                                        icon: 'text-xs p-0 m-1 pi pi-fw pi-folder-open', 
                                        children: [
                                            {
                                                key: 'A'+LC_Legend.current.id+class_id+'0',
                                                label: 'Characteristics',
                                                icon: 'text-xs p-0 m-1 pi pi-fw pi-cog',
                                                children: [...CC_children]
                                            },
                                            ...patterns
                                        ] 
                                    }
                                ];
            });
        };
        setDisplayClasses(classes);
    },[rerenderTrigger]);
    
    const [nodes, setNodes] = useState([]);
    useEffect(() => {
        setNodes(
            [{
                key: 'A|'+LC_Legend.current.id,
                label: LC_Legend.current.legend_name,
                icon: 'text-xs p-1 m-1 pi pi-fw pi-briefcase',
                children: [...displayClasses]
            }]
        );
    },[rerenderTrigger,displayClasses]);

    const togglerTemplate = (node: TreeNode, options: TreeTogglerTemplateOptions) => {
        if (!node) {
            return;
        }
        const expanded = options.expanded;
        const iconClassName = classNames('text-xs p-0 m-0 p-tree-toggler-icon pi pi-fw', {
            'text-xs p-0 m-0 pi-caret-right': !expanded,
            'text-xs p-0 m-0 pi-caret-down': expanded
        });
        return (
            <button type="button" className="text-xs p-0 m-0 p-tree-toggler p-link" tabIndex={-1} onClick={options.onClick}>
                <span className={iconClassName} aria-hidden="true"></span>
            </button>
        );
    };

    const [expandedNodes, setExpandedNodes] = useState({'A|1': true, 'A|1|1': true});
    const [expandedElements, setExpandedElements] = useState({'1001': true, '1002': true});
    const [activeLCBlock,setActiveLCBlock] = useState<any>(false);

    return (        
        <div className="card flex flex-row justify-content-center m-0 p-0 align-self-start" style={{ height: '100%' }}>
            <Toast ref={toast} />
            <div className="guide-LCMLElements" style={{ height: '100%', overflow: 'auto' }}>
                <Tree value={elements} selectionMode="single" selectionKeys={activeLCBlock} onSelectionChange={(e) => setActiveLCBlock(e.value)} expandedKeys={expandedElements} togglerTemplate={togglerTemplate} filter filterMode="strict" filterPlaceholder="Search LCML Elements" className="p-0 m-0 w-full flex-grow text-xs p-inputtext-sm" style={{ minWidth: '240px', minHeight: '100%', backgroundColor: 'var(--blue-50)' }} onNodeDoubleClick={(e)=>constructLCBlock(e.node.key)} />
            </div>
            <div className="guide-myLegend" style={{ height: '100%', overflow: 'auto' }}>
                <Tree value={nodes} selectionMode="single" selectionKeys={activeLCElement} onSelectionChange={(e) => setActiveLCElement(e.value)} expandedKeys={expandedNodes} togglerTemplate={togglerTemplate} filter filterMode="strict" filterPlaceholder="Search Your Legend" className="p-0 m-0 w-full flex-grow text-xs p-inputtext-sm" style={{ minWidth: '210px', minHeight: '100%' }} />
            </div>
        </div>
    )
}