import { processRules,processElementRules } from "./ProcessRules";
import { Rules } from "./RuleBuilder/ConnectionRules";

export const ProcessLegend = (subject,rules) => {  
  const connectionRules = Rules[rules];
  const subProcessLegend = (legend) => {
    let matchingRules = [];

    let inclusiveClassRules = null;
    let inclusiveHPRules = null;
    let inclusiveStratumRules = null;
    let inclusiveElementRules = null;
    let exclusiveClassRules = null;
    let exclusiveHPRules = null;
    let exclusiveStratumRules = null;
    let exclusiveElementRules = null;
    connectionRules.map((ruleSets) => {
      let matchClass = processRules(legend['LCT_Class'],ruleSets.inclusive.classes.rules);
      if(matchClass['properties'].length > 0){
        if(matchClass['characteristics'].length > 0){
          inclusiveClassRules = matchClass['characteristics'];
        }
      }
      //console.log(matchClass);
      matchClass = processRules(legend['LCT_Class'],ruleSets.exclusive.classes.rules);
      if(matchClass['properties'].length > 0){
        if(matchClass['characteristics'].length > 0){
          exclusiveClassRules = matchClass['characteristics'];
        }
      }
      //console.log(matchClass);

      let matchHP = processRules(legend['LCT_HorizontalPatterns'],ruleSets.inclusive.horizontalPatterns.rules);
      if(matchHP['properties'].length > 0){
        if(matchHP['characteristics'].length > 0){
          inclusiveHPRules = matchHP['characteristics'];
        }
      }
      //console.log(matchHP);
      matchHP = processRules(legend['LCT_HorizontalPatterns'],ruleSets.exclusive.horizontalPatterns.rules);
      if(matchHP['properties'].length > 0){
        if(matchHP['characteristics'].length > 0){
          exclusiveHPRules = matchHP['characteristics'];
        }
      }
      //console.log(matchHP);

      let matchStratum = processRules(legend['LCT_Strata'],ruleSets.inclusive.strata.rules);
      if(matchStratum['properties'].length > 0){
        if(matchStratum['characteristics'].length > 0){
          inclusiveStratumRules = matchStratum['characteristics'];
        }
      }
      //console.log(matchStratum);
      matchStratum = processRules(legend['LCT_Strata'],ruleSets.exclusive.strata.rules);
      if(matchStratum['properties'].length > 0){
        if(matchStratum['characteristics'].length > 0){
          exclusiveStratumRules = matchStratum['characteristics'];
        }
      }
      //console.log(matchStratum);

      //console.log(legend['LCT_Properties']);
      //console.log(ruleSets.inclusive.elements);
      let matchElement = processElementRules(legend['LCT_Properties'],legend['LCT_Characteristics'],ruleSets.inclusive.elements);
      //console.log(matchElement);
      inclusiveElementRules = matchElement;
      //console.log(inclusiveElementRules);
      //console.log(ruleSets.exclusive.elements);
      matchElement = processElementRules(legend['LCT_Properties'],legend['LCT_Characteristics'],ruleSets.exclusive.elements);
      //console.log(matchElement);
      exclusiveElementRules = matchElement;
      //console.log(exclusiveElementRules);

      /*console.log(inclusiveClassRules,exclusiveClassRules);
      console.log(inclusiveHPRules,exclusiveHPRules);
      console.log(inclusiveStratumRules,exclusiveStratumRules);
      console.log(inclusiveElementRules,exclusiveElementRules);*/

      if(inclusiveClassRules && inclusiveHPRules && inclusiveStratumRules && inclusiveElementRules.length > 0){
        
          matchingRules.push({rule_id: ruleSets.id, rule_name: ruleSets.name, rule_priority: ruleSets.priority, rule_syntax: ruleSets.syntax, assignment: ruleSets.assignment, 
            classes: {include: inclusiveClassRules, exclude: exclusiveClassRules}, 
            horizontalPatterns: {include: inclusiveHPRules, exclude: exclusiveHPRules}, 
            strata: {include: inclusiveStratumRules, exclude: exclusiveStratumRules},
            elements: {include: inclusiveElementRules, exclude: exclusiveElementRules},
          });
        
      }      
    });
    //console.log(matchingRules);
    return(
      matchingRules
    )
  }

  return (
    subProcessLegend(subject)
  );
}