import { useState,useRef,useEffect } from "react";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { TreeView } from './TreeView';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Login } from './Login';
import { Sidebar } from 'primereact/sidebar';
import { Timeline } from 'primereact/timeline';
import { MenuBar } from "./MenuBar";
import { SubMenuBar } from "./SubMenuBar";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { InputText } from "primereact/inputtext";
import { Dropdown,DropdownChangeEvent } from 'primereact/dropdown';
import { InputNumber, InputNumberChangeEvent } from "primereact/inputnumber";
import { Slider, SliderChangeEvent } from "primereact/slider";
import { ColorPicker, ColorPickerChangeEvent } from 'primereact/colorpicker';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import randomstring from "randomstring";
import { parseString } from 'xml2js';
import * as processors from 'xml2js/lib/processors';
import axios from 'axios';
import Papa from 'papaparse';

import { searchObj } from "../App";
import { searchObjKeyVal } from "../App";
import { fuzzySearchObjKeyVal } from "../App";

import { GuideLegendCreation } from "./Guides/GuideLegendCreation";
import { GuideLegendCreation2 } from "./Guides/GuideLegendCreation2";
import { GuideLegendCreation3 } from "./Guides/GuideLegendCreation3";
import { GuideLegendCreation4 } from "./Guides/GuideLegendCreation4";
import { LCMLEditor } from "./LCMLEditor";
import { Properties } from "./Properties";
import { Characteristics } from "./Characteristics";
import { Toast } from "primereact/toast";
import { LegendLoader } from "./LegendLoader";
import { Export } from "./Export";
import { Flow } from './mermaid/Flow';
import { Guide } from "./Guide";

export default function UserInterface(props) {
    const { UUID,translator,legendTemplate,options,blocks,blockLookUp,characteristics,characteristicLookUp } = props;
    const [visibleLogin, setLoginVisible] = useState<boolean>(false);
    const [visibleSideBar, setSideBarVisible] = useState<boolean>(false);
    const [visibleGuide1, setGuide1Visible] = useState<boolean>(false);
    const [visibleGuide2, setGuide2Visible] = useState<boolean>(false);
    const [visibleGuide3, setGuide3Visible] = useState<boolean>(false);
    const [visibleGuide4, setGuide4Visible] = useState<boolean>(false);
    const [visibleFileUpload, setFileUploadVisible] = useState<boolean>(false);
    const [visibleExport, setExportVisible] = useState<boolean>(false);
    const [visibleGuide, setGuideVisible] = useState<boolean>(false);

    const [triggerFullExport, setFullExport] = useState<boolean>(false);

    const [legendValid,isLegendValid] = useState<boolean>(false);
    const [rerenderTrigger, setRerenderTrigger] = useState<string>('');

    const LC_Legend = useRef<{id:number,legend_name:string,legend_description:string,legend_author:string}>({id:1,legend_name:'Legend',legend_description:'Legend description',legend_author:''});
    const LC_Class = useRef<any>([{legend_id:1,class_id:1,class_name:'Class 1',class_description:'The land characterization class',class_map_code:'LCR1',class_color_code:'FF0000'}]);
    const classesNumber = useRef(1);

    const LC_ClassCharacteristics = useRef<any>({});

    const LC_HorizontalPatterns = useRef<any>([]);
    const LC_Strata = useRef<any>([]);
    const horizontalPatternNumber = useRef(1);
    const strataNumber = useRef(1);

    const LC_Properties = useRef<any>([]);
    const stratumPropertyNumber = useRef(1);
    const LC_Characteristics = useRef<any>([]);
    const stratumCharacteristicNumber = useRef(1);

    const [activeLCElement,setActiveLCElement] = useState<any>(false);
    
    const displayOptions = (optionGroup)=>{
        let displayOptions = [];
        let _1_options = options.current["LC_Options"]?.filter( selectedOptions => selectedOptions.option_name === optionGroup );               
        if(_1_options !== undefined){                    
            Object.keys(_1_options).forEach((key0) => {     
                let Groups = [];       
                Object.keys(_1_options[key0]).forEach((key1) => {
                    if(_1_options[key0] !== "options"){
                        Groups = [...Groups, {[key1]: _1_options[key0][key1]}];
                    }else{
                        let Options = [];
                        Object.keys(_1_options[key0]).forEach((key1) => {                   
                            Options = [...Options, {[key1]: _1_options[key0][key1]}];
                        });
                            Groups = [...Groups, ...Options];
                    }                
                });
                displayOptions = [...displayOptions, {...Groups}];
            });
        }
        return(displayOptions);
    }
    const optionsGenerator = (option_name) => {        
        let optionDisp = [];
        let Options = displayOptions(option_name);
        let ReturnedOptions = [];
        if(Options[0] !== undefined){
            Object.keys(Object.entries(Options[0][4]['options'])).forEach((key0) => {
                optionDisp = [...optionDisp, {label: Options[0][4]['options'][key0]['label']}];
            });
            ReturnedOptions = [...optionDisp].map(options => options);            
        }
        return(ReturnedOptions);
    }
    var OptionsBus = {};
    OptionsBus = {...OptionsBus,
        Artificiality: optionsGenerator('artificiality_types'),
        Boolean: optionsGenerator('Boolean'),
        CoarseMineralFragmentTypes: optionsGenerator('coarse_mineral_fragment_types'),
        ConstructionStatuses: optionsGenerator('construction_statuses'),
        Directions: optionsGenerator('directions'),
        DumpSiteTypes: optionsGenerator('dump_site_types'),
        DuneTypes: optionsGenerator('duneTypes'),
        ElementHorizontalSpreading: optionsGenerator('element_horizontal_spreading_type'),
        ElementPresenceTypes: optionsGenerator('element_presence_types'),
        ErosionTypes: optionsGenerator('erosion_types'),
        ExtractionTypes: optionsGenerator('extraction_types'),
        FloatStatus: optionsGenerator('float_status'),
        FloatingIceTypes: optionsGenerator('floating_ice_types'),
        GeoAspectTypes: optionsGenerator('geo_aspect_types'),
        GroupOfPlantSpeciesTypes: optionsGenerator('groupOfPlantSpeciesTypes'),
        GrowthFrequencies: optionsGenerator('growth_frequencies'),
        GrowthPeriods: optionsGenerator('length_of_growing_periods'),
        HardpanTypes: optionsGenerator('hardpan_types'),
        HerbaceousLeafPhenologies: optionsGenerator('herbaceous_leaf_phenologies'),
        InorganicDepositTypes: optionsGenerator('inorganicDepositTypes'),
        IrrigationTypes: optionsGenerator('irrigationTypes'),
        LandFormTypes: optionsGenerator('land_form_types'),
        LeafArragements: optionsGenerator('leaf_arrangements'),
        LeafAspects: optionsGenerator('leaf_aspects'),
        LeafCharacterSizeTypes: optionsGenerator('leaf_character_size_types'),
        LeafPhenologies: optionsGenerator('leaf_phenologies'),
        LeafShapes: optionsGenerator('leaf_shapes'),
        LeafTypes: optionsGenerator('leaf_types'),
        LeafVenations: optionsGenerator('leaf_venations'),
        LifeFormSpecilizationTypes: optionsGenerator('life_form_specialization_types'),
        MacropatternTypes: optionsGenerator('macropattern_types'),
        MechanicalErosionControls: optionsGenerator('ploughTypes'),
        NutrientLevels: optionsGenerator('nutrient_levels'),
        OnTopTypes: optionsGenerator('on_top_types'),
        OrganicDepositTypes: optionsGenerator('organicDepositTypes'),
        PeriodUnits: optionsGenerator('period_units'),
        PeriodVariations: optionsGenerator('period_variations'),
        PloughTypes: optionsGenerator('ploughTypes'),
        PresenceTypes: optionsGenerator('presence_types'),
        RockAgeTypes: optionsGenerator('rock_age_types'),
        RootingStatus: optionsGenerator('rooting_status'),
        SinglePlantSpeciesTypes: optionsGenerator('singlePlantSpeciesTypes'),
        SlopeClassTypes: optionsGenerator('slope_class_types'),
        SnowTypes: optionsGenerator('snow_types'),
        TermalZones: optionsGenerator('termal_zones'),
        TerrestrialIceTypes: optionsGenerator('terrestrial_ice_types'),
        UnitsOfMeasureArea: optionsGenerator('units_of_measure_area'),
        VegetationArtificialityTypes: optionsGenerator('vegetationArtificialityTypes'),
        WaterAndIceDynamics: optionsGenerator('water_ice_dynamics'),
        WaterBodyPositions: optionsGenerator('water_body_positions'),
        WaterSalinityTypes: optionsGenerator('water_salinity_types'),
        WoodyLeafPhenologies: optionsGenerator('woody_leaf_phenologies'),
        sequentialTemporalRelationshipTypes: optionsGenerator('sequential_temporal_relationship'),             
    }

    useEffect(()=>{
        isLegendValid(false);
    },[rerenderTrigger]);
    
    const {
        control,
        formState: { errors },
        register,
        unregister,
        handleSubmit,
        getValues,
        setValue,
        watch,
        reset
    } = useForm({ });
    const { 
        fields, 
        append, 
        remove, 
        move,
        insert
      } = useFieldArray({
        control,
        name: "items"
      });
    const getFormErrorMessage = (name:any) => {
        return errors[name] ? <small className="p-error">This field is required</small> : <small className="p-error">&nbsp;</small>;
    };
    
    const returnStrata = (HPID)=>{
        let displayStrata = [];
        let filtered = LC_Strata.current.filter(stratum => stratum.HPID === parseInt(HPID['value']));
        Object.values(filtered).forEach((HP)=>{
            displayStrata.push({label: HP['name']});
        });
        return(displayStrata);
    }

    const formReconfig = (element,value)=>{
        let option = options.current["LC_Options"]?.filter( selectedOptions => selectedOptions.option_name === element );
        let optionDetails = option[0].options.find(o => o.label === value.label);
        if(optionDetails!=undefined){
            let show = optionDetails['show']?.split(',');
            show?.map((element)=>{            
                const timer = setTimeout(() => {                    
                    eval('document.getElementById( "'+element.trim()+'" ).style.display = ""');
                }, 20);
            });
            let hide = optionDetails['hide']?.split(',');
            hide?.map((element)=>{            
                const timer = setTimeout(() => {                    
                    eval('document.getElementById( "'+element.trim()+'" ).style.display = "none"');                    
                }, 20);
            });
        }
    }

    const displayFormElements = (elements,elementSuffix='')=>{ 
        var ctlr = [];
        for (const [key, value] of Object.entries(elements)) {            
            let component = null;
            let required = "";            
            switch(value['element_type']) {
                case "Range":
                    component = <Controller
                                key={"Range"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue={[value['element_rules']['min'],value['element_rules']['max']]}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-2 pl-3 pr-3 m-0">{value['element_label']}:</span>
                                            <div className="card flex flex-column w-full gap-0">
                                            <Slider name={field.name} value={field.value as any} onChange={(e: SliderChangeEvent) => field.onChange(e.value as number[])} className="p-inputtext-sm text-xs p-0 m-0" range style={{width: '100%', alignSelf: 'center',verticalAlign: 'middle'}} min={value['element_rules']['min']} max={value['element_rules']['max']} required={value['element_rules']['required']} />
                                            <span className="text-xs p-0 m-0 mt-2" style={{ textAlign: 'center' }}>{field.value[0]} - {field.value[1]}</span>
                                            </div>
                                        <span className="p-inputgroup-addon text-xs p-2 m-0">{value['element_rules']['symbol']}</span>                                    
                                    </div>
                                    )}
                                />;
                    break;
                case "Dropdown":                
                    component = <Controller
                                key={"Dropdown"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue=""
                                rules={{ required:value['element_rules']['required'] }}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-s p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <Dropdown inputId={field.name} name={field.name} value={field.value} onChange={(e: DropdownChangeEvent) => {field.onChange(e.value);formReconfig(value['element_rules']['options_name'],e.value)}} inputRef={field.ref} options={eval(value['element_rules']['list'])} optionLabel="label" placeholder={"Select a "+value['element_label']} className="p-inputtext-sm text-xs p-0 m-0" editable tooltip={value['element_label']} tooltipOptions={{ event: 'both' }} />
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">{value['element_rules']['symbol']}</span>
                                    </div>
                                    )}
                                />;        
                    break;            
                case "Number":
                    if(value['element_name'] != "class_id"){
                        component = <Controller
                                key={"Number"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue={value['element_rules']['min']}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <InputNumber name={field.name} value={field.value} className="p-inputtext-sm text-xs p-1 m-0" onValueChange={(e) => field.onChange(e.target.value)} showButtons buttonLayout="horizontal" style={{ width: '100%' }} min={value['element_rules']['min']} max={value['element_rules']['max']} placeholder={value['element_label']} required={value['element_rules']['required']} tooltip={value['element_label']} tooltipOptions={{ event: 'both' }} />                                        
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">{value['element_rules']['symbol']}</span>
                                    </div>
                                    )}
                                />;
                    }
                    else{
                        component = <Controller
                                key={"Number"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                defaultValue={value['element_rules']['min']}
                                render={({ field, fieldState }) => (                                    
                                        <input type="hidden" id={field.name} name={field.name} value={field.value} />
                                    )}
                                />;                            
                    }
                    break;
                case "Color":
                    component = <Controller
                                key={"Color"+key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}                            
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">
                                            <i className="pi pi-palette"></i>
                                        </span>
                                        <div className="flex flex-row" style={{ width: '100%' }}>
                                            <ColorPicker format="hex" className="p-0 m-0" onChange={(e) => field.onChange(e.value)} style={{ width: '40px' }} />
                                            <p className="flex align-self-center p-0 m-0">&lt;--select color here #</p>
                                            <InputText name={field.name} value={field.value} className="p-inputtext-sm text-s  p-2 m-0" style={{ width: '30%'}} prefix="#" />
                                        </div>
                                    </div>
                                    )}
                                />;
                    break;
                default:
                    component = <Controller
                                key={key}
                                name={value['element_name']+elementSuffix}
                                control={eval('control')}
                                render={({ field, fieldState }) => (
                                    <div className="p-inputgroup flex-1" id={field.name}>
                                        <span className="p-inputgroup-addon text-xs p-0 m-0">
                                            <i className="pi pi-pencil"></i>
                                        </span>
                                        <InputText name={field.name} value={field.value} className="p-inputtext-sm text-xs p-1 m-0" onChange={(e) => field.onChange(e.target.value)} style={{ width: '100%' }} placeholder={value['element_label']} required={value['element_rules']['required']} tooltip={value['element_label']} tooltipOptions={{ event: 'both' }} />
                                    </div>
                                    )}
                                />;
            }
            ctlr.push(component);
        };        
        return (<>{ctlr}</>);
    }
    
    const customIcons = (
        <>
            <button className="p-sidebar-icon p-link mr-2">
                <span className="pi pi-print" onClick={window.print}/>
            </button>
        </>
    );
    const LCMLHeader = (
        <h2 style={{ margin: 0 }}>Inspect Legend Land Cover MetaLanguage(LCML)</h2>
    );
    const LegendImportHeader = (
        <h2 style={{ margin: 0 }}>Import Land Characterization Legend</h2>        
    );
    const ExportHeader = (
        <h2 style={{ margin: 0 }}>Export your Legend</h2>
    );

    const customizedMarker = (item:any) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };
    const customizedContent = (item:any) => {
        return (
            <p className="text-xs">{item.label}</p>
        );
    };
    
    const guideTransition = (step:number) => {
        const events = [
            {id: 1, label: 'Legend', icon: 'pi pi-cog', color: ''}, 
            {id: 2, label: 'Class', icon: 'pi pi-cog', color: ''}, 
            {id: 3, label: 'Strata', icon: 'pi pi-cog', color: ''}, 
            {id: 4, label: 'Elements', icon: 'pi pi-cog', color: ''}];
        
        events.map(event => {
            if(event.id === step) event.color = "var(--primary-color)";
            if(event.id < step) event.color = "var(--gray-500)";
        })

        return (
            <div className="card flex flex-row gap-1 w-full">            
                <Timeline value={events} layout="horizontal" align="bottom" marker={customizedMarker} content={customizedContent} />
            </div>        
        );
    }

    const rerender = useRef(false);
    const [elements,setElements] = useState<any>([]);
    const [legend,setLegend] = useState<any>({});
    const classCharacteristics = useRef<any>([]);
    useEffect(()=>{
        if(!rerender.current){
            let elements = [];    
            Object.keys(blockLookUp.current).forEach((key0) => { 
                let _1_parents = blockLookUp.current[key0].filter( block => block.parent_id === null );
                Object.keys(_1_parents).forEach((key1) => {
                    let _1_blocks = blocks.current["LC_Block"]?.filter( block => block.block_id === _1_parents[key1]["block_id"] );
                    Object.keys(_1_blocks).forEach((key2) => {
                        let _2_parents = blockLookUp.current[key0].filter( block => block.parent_id === _1_blocks[key2]["block_id"] );
                        let _1_children = [];
                        Object.keys(_2_parents).forEach((key3) => {
                            let _3_parents = blockLookUp.current[key0].filter( block => block.parent_id === _2_parents[key3]["block_id"] );
                            let _2_blocks = blocks.current["LC_Block"]?.filter( block => block.block_id === _2_parents[key3]["block_id"] );                    
                            let _2_children = [];
                            Object.keys(_3_parents).forEach((key4) => {
                                let _4_parents = blockLookUp.current[key0].filter( block => block.parent_id === _3_parents[key4]["block_id"] );
                                let _3_blocks = blocks.current["LC_Block"]?.filter( block => block.block_id === _3_parents[key4]["block_id"] );
                                let _3_children = [];
                                Object.keys(_4_parents).forEach((key5) => {
                                    let _5_parents = blockLookUp.current[key0].filter( block => block.parent_id === _4_parents[key5]["block_id"] );
                                    let _4_blocks = blocks.current["LC_Block"]?.filter( block => block.block_id === _4_parents[key5]["block_id"] );
                                    let _4_children = [];
                                    Object.keys(_5_parents).forEach((key6) => {                                        
                                        let _5_blocks = blocks.current["LC_Block"]?.filter( block => block.block_id === _5_parents[key6]["block_id"] );
                                        _4_children = [..._4_children, { key: _1_blocks[0]["block_id"]+"-"+_2_blocks[0]["block_id"]+"-"+_3_blocks[0]["block_id"]+"-"+_4_blocks[0]["block_id"]+"-"+_5_blocks[0]["block_id"], label: _5_blocks[0]["block_label"], icon: _5_blocks[0]["block_icon"]}];
                                    });
                                    _3_children = [..._3_children, { key: _1_blocks[0]["block_id"]+"-"+_2_blocks[0]["block_id"]+"-"+_3_blocks[0]["block_id"]+"-"+_4_blocks[0]["block_id"], label: _4_blocks[0]["block_label"], icon: _4_blocks[0]["block_icon"], children: _4_children}];
                                });
                                _2_children = [..._2_children, { key: _1_blocks[0]["block_id"]+"-"+_2_blocks[0]["block_id"]+"-"+_3_blocks[0]["block_id"], label: _3_blocks[0]["block_label"], icon: _3_blocks[0]["block_icon"], children: _3_children}];
                            });                                      
                            _1_children = [..._1_children, { key: _1_blocks[0]["block_id"]+"-"+_2_blocks[0]["block_id"], label: _2_blocks[0]["block_label"], icon: _2_blocks[0]["block_icon"], children: _2_children}];
                        });                
                        elements = ([...elements, { key: ""+_1_blocks[0]["block_id"], label: _1_blocks[0]["block_label"], icon: _1_blocks[0]["block_icon"], children: _1_children}]);
                    });            
                });                
            });
            setElements(elements);
            if(legendTemplate.current["LC_ClassCharacteristics"] !== undefined){
                let ClassCharacteristicElements = [];
                legendTemplate.current["LC_ClassCharacteristics"].map((Characteristics)=>{
                    let classCharacteristicElements = {};            
                    Object.entries(Characteristics).forEach((Characteristic)=>{
                        let classDataElements = [];
                        classCharacteristicElements = {...classCharacteristicElements, [Characteristic[0]]: Characteristic[1]};
                        if(Characteristic[0] === "elements"){
                            Object.entries(Characteristic[1]).forEach((characteristicElement)=>{
                                if(characteristicElement[1]["element_type"] === "Number")
                                    classDataElements = {...classDataElements, [characteristicElement[1]["element_name"]]: characteristicElement[1]["element_rules"]['min']};
                                else if(characteristicElement[1]["element_type"] === "Range")
                                    classDataElements = {...classDataElements, [characteristicElement[1]["element_name"]]: [characteristicElement[1]["element_rules"]['min'],characteristicElement[1]["element_rules"]['max']]};
                                else
                                    classDataElements = {...classDataElements, [characteristicElement[1]["element_name"]]: ""};
                            });
                        }
                        classCharacteristicElements = {...classCharacteristicElements, "defaults": {...classDataElements}};
                    });
                    ClassCharacteristicElements.push(classCharacteristicElements);
                });
                classCharacteristics.current = ClassCharacteristicElements;
                setLegend(legendTemplate.current);
            }
        }
        if(elements.length > 0 && legend.toString().length > 0)
            rerender.current = true;
    },[elements,legend]);

    const toast = useRef(null);
    const validationErrors = useRef<any>([]);
    const Validator = ()=>{
        validationErrors.current = [];
        if(LC_Legend.current.legend_name === "" || LC_Legend.current.legend_description === "" || LC_Legend.current.legend_author === "")
            validationErrors.current = [...validationErrors.current, {"Legend": "Missing Metadata: Legend Name, Description or Author missing"}];
        else
            toast.current.show({ severity: 'success', summary: 'Legend Metadata Details Passed', detail: "Legend Name, Description & Author Validated", life: 3000 });

        let empty_class_names = searchObjKeyVal(LC_Class.current,'class_name','');
        let empty_class_descriptions = searchObjKeyVal(LC_Class.current,'class_description','');        
        if(empty_class_names.length > 0 || empty_class_descriptions.length > 0){
            let displayNames = "";
            empty_class_names.map((clss)=>{
                displayNames = displayNames+clss.class_id+", "
            });
            displayNames = "Missing Class Name on Class: "+displayNames;
            let displayDescription = "";
            empty_class_descriptions.map((clss)=>{
                displayDescription = displayDescription+clss.class_id+", "
            });
            displayDescription = "Missing Class Description on Class: "+displayDescription;
            validationErrors.current = [...validationErrors.current, {"Class": displayNames+" "+displayDescription}];
        }
        else
            toast.current.show({ severity: 'success', summary: 'Class Metadata Details Passed', detail: "Class Name & Description Validated", life: 3000 });

        let classCheck = LC_Class.current?.filter(({ class_id: id1 }) => !LC_HorizontalPatterns?.current.some(({ class_id: id2 }) => id2 === id1));
        let stratumCheck = LC_HorizontalPatterns?.current.filter(({ horizontal_pattern_id: id1 }) => !LC_Strata?.current.some(({ HPID: id2 }) => id2 === id1));
        let HP = LC_HorizontalPatterns?.current.length;
        let str = LC_Strata?.current.length;        
        if(HP > 0 && stratumCheck?.length === 0 && classCheck?.length === 0)
            toast.current.show({ severity: 'success', summary: 'Horizontal Patterns & Strata Passed', detail: "Horizontal Patterns: "+HP+" Strata: "+str+" Validated", life: 3000 });
        else{            
            if(HP === 0)
                validationErrors.current = [...validationErrors.current, {"Horizontal Patterns & Strata": "Missing Horizontal Pattern and Stratum. At least 1 Horizontal Pattern and 1 Stratum are required."}];
            if(stratumCheck?.length > 0)
                validationErrors.current = [...validationErrors.current, {"Strata": "Stratum for "+stratumCheck?.map((pattern)=>(pattern['name']))+" is undefined."}];
            if(classCheck?.length > 0)
                validationErrors.current = [...validationErrors.current, {"Horizontal Patterns": "Horizontal Pattern for "+classCheck?.map((clss)=>(clss.class_name))+" is undefined."}];
        }

        const propertyCheck = LC_Strata?.current.filter(({ stratumID: id1 }) => !LC_Properties?.current.some(({ StratumID: id2 }) => id2 === id1));        
        let SP = LC_Properties?.current.length;
        let chr = LC_Characteristics?.current.length;
        
        if(SP > 0 && propertyCheck.length === 0)
            toast.current.show({ severity: 'success', summary: 'Elements, Properties & Characteristics Passed', detail: "Elements Properties: "+SP+" Characteristics: "+chr+" Validated", life: 3000 });
        else{
            validationErrors.current = [...validationErrors.current, {"Elements": "Missing Elements - Element Properties: "+SP+" Characteristics: "+chr }];
            toast.current.show({ severity: 'error', summary: 'Elements Error', detail: "Properties: "+SP+" Characteristics: "+chr, life: 60000 });
            if(SP === 0)
                validationErrors.current = [...validationErrors.current, {"Elements & Properties": "Missing Stratum Elements. At least 1 Element is required per Stratum."}];
            else
                validationErrors.current = [...validationErrors.current, {"Elements & Properties": "Element Properties for "+propertyCheck?.map((property)=>(property['name']))+" are undefined."}];
            SP === 0? toast.current.show({ severity: 'error', summary: 'Element & Property Error', detail: "Stratum Elements and Properties are required.", life: 60000 }) : toast.current.show({ severity: 'error', summary: 'Property Error', detail: "Properties for "+propertyCheck?.map((property)=>(property['name']))+" are undefined.", life: 60000 });
        }

        if(validationErrors.current.length > 0){
            validationErrors.current.map((error)=>{
                Object.entries(error).forEach((errorDetail)=>{
                    toast.current.show({ severity: 'error', summary: errorDetail[0]+' Fail', detail: errorDetail[1], life: 60000 });
                });
            });
            isLegendValid(false);
            return(false);
        }
        else{
            isLegendValid(true);
            return(true);
        }        
    }

    const LC_Objectfilter = (obj, searchField, searchParameter) =>{
        let match = [];
        if(obj !== undefined){
            Object.keys(obj).forEach((key)=>{
                Object.entries(obj[key]).forEach((entries)=>{
                    if(entries[0] === searchField && entries[1] === parseInt(searchParameter))
                        match = [...match, {objectID: parseInt(key), ...obj[key]}];
                });
            });
        }
        return(match);
    }

    const accept = () => {        
        window.location.reload();        
    }
    const reject = () => {
        toast.current?.show({ severity: 'info', summary: 'Cancelled', detail: 'Action Cancelled', life: 3000 });
    }
    const confirmNewLegend = (message,header,icon,acceptStyle) => {
        confirmDialog({
            message: message,
            header: header,
            icon: icon,
            acceptClassName: acceptStyle,
            accept,
            reject
        });
    };

    const LCMLError = useRef(false);
    const translateLegend = (Legend,LegendType,FileName='')=>{    
        let translation = translator[LegendType];
        let errors = [];
        let errFormat = [];
        let errClassCharacteristics = [];
        let errBlock = [];
        let errCharacteristic = [];
        if(Legend === null)
            return null;
        else if(LegendType === "lchs" || LegendType === "lccs" || LegendType === "xml"){
            parseString(Legend, {trim: true,explicitArray: false,attrValueProcessors: [processors.parseBooleans, processors.parseNumbers],valueProcessors: [processors.parseBooleans, processors.parseNumbers]}, (err, result) => {
                if (err) {
                    console.error('Error parsing XML:', err);
                    alert('Error parsing file, please try again!');
                } else {
                    if(LegendType === "lchs"){
                        let ClassXteristic = {};
                        Object.entries(result['LC_Legend']['objects']['LC_ClassCharacteristics']).forEach((Xteristic)=>{
                            ClassXteristic = {...ClassXteristic, [String(Xteristic[0].replace("_",""))]:Xteristic[1]};
                        });
                        LC_Legend.current = result['LC_Legend']['$'];
                        LC_Class.current = result['LC_Legend']['objects']['LC_Class'];
                        if(!Array.isArray(LC_Class.current))
                            LC_Class.current = [LC_Class.current];
                        classesNumber.current = LC_Class.current.length+1;
                        LC_ClassCharacteristics.current = ClassXteristic;
                        LC_HorizontalPatterns.current = result['LC_Legend']['objects']['LC_HorizontalPatterns'];
                        if(!Array.isArray(LC_HorizontalPatterns.current))
                            LC_HorizontalPatterns.current = [LC_HorizontalPatterns.current];
                        horizontalPatternNumber.current = LC_HorizontalPatterns.current.length+1;
                        LC_Strata.current = result['LC_Legend']['objects']['LC_Strata'];
                        if(!Array.isArray(LC_Strata.current))
                            LC_Strata.current = [LC_Strata.current];
                        strataNumber.current = LC_Strata.current.length+1;
                        LC_Properties.current = result['LC_Legend']['objects']['LC_Properties'];
                        if(!Array.isArray(LC_Properties.current))
                            LC_Properties.current = [LC_Properties.current];
                        stratumPropertyNumber.current = LC_Properties.current.length+1;
                        LC_Characteristics.current = result['LC_Legend']['objects']['LC_Characteristics'];
                        if(LC_Characteristics.current === undefined)
                            LC_Characteristics.current = [];
                        else if(!Array.isArray(LC_Characteristics.current))
                            LC_Characteristics.current = [LC_Characteristics.current];
                        stratumCharacteristicNumber.current = LC_Characteristics?.current.length+1;
                        setFileUploadVisible(false);
                        setRerenderTrigger('Legend Import'+randomstring.generate(8));
                    } else {
                        LC_Legend.current = {id: 1, legend_name: 'Legend', legend_description: 'Legend description', legend_author: FileName};
                        LC_Class.current = [{legend_id: 1, class_id: 1, class_name: 'Class 1', class_description: 'The land characterization class', class_map_code: 'LCR1', class_color_code: 'FF0000'}];
                        classesNumber.current = 1;
                        LC_ClassCharacteristics.current = {};
                        LC_HorizontalPatterns.current = [];
                        horizontalPatternNumber.current = 1;
                        LC_Strata.current = [];
                        strataNumber.current = 1;
                        LC_Properties.current = [];
                        stratumPropertyNumber.current = 1;
                        LC_Characteristics.current = [];
                        stratumCharacteristicNumber.current = 1;                        
                        Object.entries(translation).forEach((translation_element)=>{
                            let translation_map = translation_element[1] as any;
                            let MaxStratumID = 0; let MaxHPID = 0; let MaxClassID = 0;
                            translation_map.forEach((translation_map)=>{
                                if(translation_element[0] === "LC_Legend")
                                    eval(translation_element[0]+".current"+"['"+translation_map.LChS_EquivalentElement+"']='"+eval("result"+translation_map.Translation)+"'");
                                else if(translation_element[0] === "LC_Class"){
                                    let classTranslation = eval("result"+translation_map.Translation);
                                    let classes = [];
                                    if(!Array.isArray(classTranslation))
                                        classTranslation = [classTranslation];
                                    classTranslation.map((clss)=>{
                                        let buildClass = {};
                                        let legend_id = null;
                                        let class_id = null;
                                        Object.entries(clss).forEach((clss_details)=>{
                                            if(clss_details[0] === "$"){
                                                legend_id = 1;
                                                class_id = parseInt(clss_details[1]['id'], 16);
                                                if(MaxClassID <= class_id)
                                                    MaxClassID = class_id;
                                                buildClass = {...buildClass, legend_id: legend_id, class_id: class_id};
                                            }
                                            else if(clss_details[0] !== "$" && clss_details[0] !== "elements"){
                                                let transClass = searchObjKeyVal(translation['LC_ClassElements'],'Translation',clss_details[0]);
                                                if(typeof(clss_details[1]) === "object"){
                                                    Object.entries(clss_details[1]).forEach((clss_obj_detail)=>{
                                                        buildClass = {...buildClass, [transClass[0]['LChS_EquivalentElement']]: [parseInt(clss_obj_detail[1]['min']),parseInt(clss_obj_detail[1]['max'])]};
                                                    });
                                                }
                                                else
                                                    buildClass = {...buildClass, [transClass[0]['LChS_EquivalentElement']]: clss_details[1]};
                                            }
                                            else if(clss_details[0] === "elements"){
                                                let ClassCharacteristics = clss_details[1]['LC_Characteristic'];  //create array for processing;
                                                let Charact = {};
                                                if(ClassCharacteristics !== undefined){
                                                    if(!Array.isArray(ClassCharacteristics))
                                                        ClassCharacteristics = [ClassCharacteristics];
                                                    ClassCharacteristics.map((ClassCharacteristic)=>{
                                                        let CharRef = ClassCharacteristic['$']['xsi:type'];
                                                        let TranslatedCharRef = searchObjKeyVal(translation["LC_ClassCharacteristics"],'Translation',CharRef);
                                                        if(TranslatedCharRef[0] !== undefined)
                                                            CharRef = TranslatedCharRef[0]['LChS_EquivalentElement'];
                                                        let refCharacteristic = searchObjKeyVal(legend["LC_ClassCharacteristics"],"characteristic_reference",CharRef);
                                                        if(refCharacteristic != undefined){
                                                            Object.entries(ClassCharacteristic).forEach((ClassCharacteristic_details)=>{
                                                                if(ClassCharacteristic_details[0] !== "$"){                                                                    
                                                                    let transCharacteristic = searchObjKeyVal(translation['LC_ClassCharacteristicElements'],'Translation',`['`+ClassCharacteristic['$']['xsi:type']+`']['`+ClassCharacteristic_details[0]+`']`);                                                                    
                                                                    if(typeof(ClassCharacteristic_details[1]) === "object"){
                                                                        Object.entries(ClassCharacteristic_details[1]).forEach((ClassCharacteristic_obj_detail)=>{
                                                                            Charact = {...Charact, [refCharacteristic[0]['characteristic_name']]: {...Charact[refCharacteristic[0]['characteristic_name']], [transCharacteristic[0]['LChS_EquivalentElement']]:[parseInt(ClassCharacteristic_obj_detail[1]['min']),parseInt(ClassCharacteristic_obj_detail[1]['max'])]}};
                                                                        });
                                                                    }
                                                                    else
                                                                        Charact = {...Charact, [refCharacteristic[0]['characteristic_name']]: {...Charact[refCharacteristic[0]['characteristic_name']], [transCharacteristic[0]['LChS_EquivalentElement']]:ClassCharacteristic_details[1]}};                                                                    
                                                                }
                                                            });
                                                        }
                                                        else
                                                            errClassCharacteristics.push(ClassCharacteristic['$']['xsi:type']);
                                                    });
                                                }
                                                LC_ClassCharacteristics.current[class_id] = Charact;
                                                
                                                let patterns = clss_details[1]['LC_HorizontalPattern'];  //create array for processing
                                                let HPs = [];
                                                let Strata = [];
                                                let Properties = [];
                                                let Characteristics = [];
                                                if(!Array.isArray(patterns))
                                                    patterns = [patterns];
                                                patterns.map((pattern)=>{
                                                    let PatternRef = pattern['$']['xsi:type'];
                                                    let buildHP = {};
                                                    let HPID = null;
                                                    Object.entries(pattern).forEach((pattern_details)=>{
                                                        if(pattern_details[0] === "$"){
                                                            HPID = parseInt(pattern_details[1]['id'], 16);
                                                            if(MaxHPID <= HPID)
                                                                MaxHPID = HPID+1;
                                                            buildHP = {...buildHP, class_id: class_id, horizontal_pattern_id: HPID};
                                                        }
                                                        else if(pattern_details[0] !== "$" && pattern_details[0] !== "elements"){
                                                            let transPattern = searchObjKeyVal(translation['LC_HorizontalPatternElements'],'Translation',pattern_details[0]);
                                                            if(typeof(pattern_details[1]) === "object"){
                                                                Object.entries(pattern_details[1]).forEach((pattern_obj_detail)=>{
                                                                    buildHP = {...buildHP, [transPattern[0]['LChS_EquivalentElement']]: [parseInt(pattern_obj_detail[1]['min']),parseInt(pattern_obj_detail[1]['max'])]};
                                                                });
                                                            }
                                                            else
                                                                buildHP = {...buildHP, [transPattern[0]['LChS_EquivalentElement']]: pattern_details[1]};
                                                        }
                                                        else if(pattern_details[0] === "elements"){
                                                            let LC_Strata = pattern_details[1]['LC_Stratum']; //create array for processing
                                                            if(!Array.isArray(LC_Strata))
                                                                LC_Strata = [LC_Strata];
                                                            LC_Strata.map((item)=>{
                                                                let buildStrata = {};
                                                                let StratumID = null;
                                                                Object.entries(item).forEach((stratum)=>{
                                                                    if(stratum[0] === "$"){
                                                                        StratumID = parseInt(stratum[1]['id'], 16);
                                                                        if(MaxStratumID <= StratumID)
                                                                            MaxStratumID = StratumID+1;
                                                                        buildStrata = {...buildStrata, HPID: HPID, stratumID: StratumID};
                                                                    }
                                                                    else if(stratum[0] !== "$" && stratum[0] !== "elements"){
                                                                        let transStratum = searchObjKeyVal(translation['LC_StratumElements'],'Translation',stratum[0]);
                                                                        if(typeof(stratum[1]) === "object"){
                                                                            Object.entries(stratum[1]).forEach((stratum_obj_detail)=>{
                                                                                buildStrata = {...buildStrata, [transStratum[0]['LChS_EquivalentElement']]: [parseInt(stratum_obj_detail[1]['min']),parseInt(stratum_obj_detail[1]['max'])]};
                                                                            });
                                                                        }
                                                                        else
                                                                            buildStrata = {...buildStrata, [transStratum[0]['LChS_EquivalentElement']]: stratum[1]};
                                                                    }
                                                                    else if(stratum[0] === "elements"){
                                                                        let LC_LandCoverElement = stratum[1]['LC_LandCoverElement']; //create array for processing
                                                                        if(!Array.isArray(LC_LandCoverElement))
                                                                            LC_LandCoverElement = [LC_LandCoverElement];
                                                                        LC_LandCoverElement.map((LC_element)=>{
                                                                            let buildProperties = {};
                                                                            let BlockID = null;
                                                                            let BlockReference = null;
                                                                            let refBlock = [];
                                                                            let block_source = "";
                                                                            Object.entries(LC_element).forEach((property)=>{
                                                                                refBlock = [];
                                                                                if(property[0] === "$"){
                                                                                    block_source = property[1]['xsi:type'];
                                                                                    let block_reference = property[1]['xsi:type'];
                                                                                    let TranslatedBlock = searchObjKeyVal(translation["LC_Blocks"],'Translation',block_reference);
                                                                                    if(TranslatedBlock[0] !== undefined)
                                                                                        block_reference = TranslatedBlock[0]['LChS_EquivalentElement'];
                                                                                    refBlock = blocks.current["LC_Block"]?.filter(block => block.block_reference === block_reference);
                                                                                    if(refBlock[0] !== undefined){
                                                                                        BlockID = refBlock[0]['block_id'];
                                                                                        BlockReference = refBlock[0]['block_reference'];
                                                                                        buildProperties = {...buildProperties, StratumID: StratumID, BlockID: BlockID, BlockReference: BlockReference};
                                                                                    }
                                                                                    else
                                                                                        errBlock.push(block_reference);
                                                                                }
                                                                                else if(property[0] !== "$" && property[0] !== "elements"){                                                                                    
                                                                                    let transProperties = searchObjKeyVal(translation['LC_BlockElements'],'Source_ElementDefinition',block_source);                                                                                    
                                                                                    let transProperty = [];
                                                                                    transProperty = searchObjKeyVal(transProperties,'Translation',property[0]);
                                                                                    if(transProperty[0] !== undefined)
                                                                                        property[0] = transProperty[0]['LChS_EquivalentElement'];
                                                                                    if(typeof(property[1]) === "object"){
                                                                                        Object.entries(property[1]).forEach((detail)=>{
                                                                                            buildProperties = {...buildProperties, [property[0]]: [parseInt(detail[1]['min']),parseInt(detail[1]['max'])]};
                                                                                        });
                                                                                    }
                                                                                    else
                                                                                        buildProperties = {...buildProperties, [property[0]]: property[1]};
                                                                                }
                                                                                else if(property[0] === "elements"){
                                                                                    let LC_Characteristic = property[1]['LC_Characteristic']; //create array for processing
                                                                                    if(!Array.isArray(LC_Characteristic))
                                                                                        LC_Characteristic = [LC_Characteristic];
                                                                                    LC_Characteristic.map((LC_Characteristic_item)=>{
                                                                                        let buildCharacteristics = {};
                                                                                        let CharacteristicID = null;
                                                                                        let CharacteristicReference = null;
                                                                                        let CharacteristicLabel = null;
                                                                                        let CharacteristicRef = null;
                                                                                        let refCharacteristic = [];
                                                                                        Object.entries(LC_Characteristic_item).forEach((characteristic)=>{
                                                                                            refCharacteristic = [];
                                                                                            if(characteristic[0] === "$"){
                                                                                                let XterRef = characteristic[1]['xsi:type'];
                                                                                                let TranslatedXterRef = searchObjKeyVal(translation["LC_Blocks"],'Translation',XterRef);
                                                                                                if(TranslatedXterRef[0] !== undefined)
                                                                                                    XterRef = TranslatedXterRef[0]['LChS_EquivalentElement'];
                                                                                                refCharacteristic = characteristics.current["LC_Characteristics"]?.filter(xteristic => xteristic.characteristic_reference === XterRef);                                                                                                
                                                                                                if(refCharacteristic[0] !== undefined){
                                                                                                    CharacteristicID = refCharacteristic[0]['characteristic_id'];
                                                                                                    CharacteristicReference = refCharacteristic[0]['characteristic_reference'];
                                                                                                    CharacteristicLabel = refCharacteristic[0]['characteristic_label'];
                                                                                                    CharacteristicRef = characteristic[1]['xsi:type'];
                                                                                                    buildCharacteristics = {...buildCharacteristics, StratumID: StratumID, BlockID: BlockID, BlockReference: BlockReference, CharacteristicID: CharacteristicID, CharacteristicReference: CharacteristicReference, CharacteristicLabel: CharacteristicLabel};
                                                                                                }
                                                                                                else
                                                                                                    errCharacteristic.push(characteristic[1]['xsi:type']);
                                                                                            }
                                                                                            else if(characteristic[0] !== "$" && characteristic[0] !== "elements"){
                                                                                                let transCharacteristics = searchObjKeyVal(translation['LC_CharacteristicElements'],'Source_ElementDefinition',CharacteristicRef);
                                                                                                let transCharacteristic = [];
                                                                                                transCharacteristic = searchObjKeyVal(transCharacteristics,'Translation',characteristic[0]);
                                                                                                if(transCharacteristic[0] !== undefined)
                                                                                                    characteristic[0] = transCharacteristic[0]['LChS_EquivalentElement'];
                                                                                                if(typeof(characteristic[1]) === "object"){
                                                                                                    Object.entries(characteristic[1]).forEach((characteristic_obj_detail)=>{
                                                                                                        buildCharacteristics = {...buildCharacteristics, [characteristic[0]]: [parseInt(characteristic_obj_detail[1]['min']),parseInt(characteristic_obj_detail[1]['max'])]};
                                                                                                    });
                                                                                                }
                                                                                                else
                                                                                                    buildCharacteristics = {...buildCharacteristics, [characteristic[0]]: characteristic[1]};
                                                                                            }
                                                                                            else if(characteristic[0] === "elements"){
                                                                                                let LC_SubCharacteristic = characteristic[1]['LC_Characteristic']; //create array for processing
                                                                                                if(!Array.isArray(LC_SubCharacteristic))
                                                                                                    LC_SubCharacteristic = [LC_SubCharacteristic];
                                                                                                LC_SubCharacteristic.map((LC_SubCharacteristic_SubItem)=>{
                                                                                                    let buildSubCharacteristics = {};
                                                                                                    let SubCharacteristicID = null;
                                                                                                    let SubCharacteristicReference = null;
                                                                                                    let SubCharacteristicLabel = null;
                                                                                                    let SubCharacteristicRef = null;
                                                                                                    let refSubCharacteristic = [];
                                                                                                    Object.entries(LC_SubCharacteristic_SubItem).forEach((SubCharacteristic)=>{
                                                                                                        refSubCharacteristic = [];
                                                                                                        if(SubCharacteristic[0] === "$"){
                                                                                                            let SubXterRef = SubCharacteristic[1]['xsi:type'];
                                                                                                            let TranslatedSubXterRef = searchObjKeyVal(translation["LC_Blocks"],'Translation',SubXterRef);
                                                                                                            if(TranslatedSubXterRef[0] !== undefined)
                                                                                                                SubXterRef = TranslatedSubXterRef[0]['LChS_EquivalentElement'];
                                                                                                            refSubCharacteristic = characteristics.current["LC_Characteristics"]?.filter(xteristic => xteristic.characteristic_reference === SubXterRef);
                                                                                                            if(refSubCharacteristic[0] !== undefined){
                                                                                                                SubCharacteristicID = refSubCharacteristic[0]['characteristic_id'];
                                                                                                                SubCharacteristicReference = refSubCharacteristic[0]['characteristic_reference'];
                                                                                                                SubCharacteristicLabel = refSubCharacteristic[0]['characteristic_label'];
                                                                                                                SubCharacteristicRef = SubCharacteristic[1]['xsi:type'];
                                                                                                                buildSubCharacteristics = {...buildSubCharacteristics, StratumID: StratumID, BlockID: BlockID, BlockReference: BlockReference, CharacteristicID: SubCharacteristicID, CharacteristicReference: SubCharacteristicReference, CharacteristicLabel: SubCharacteristicLabel};
                                                                                                            }
                                                                                                            else
                                                                                                                errCharacteristic.push(SubCharacteristic[1]['xsi:type']);
                                                                                                        }
                                                                                                        else if(SubCharacteristic[0] !== "$" && SubCharacteristic[0] !== "elements"){
                                                                                                            let transSubCharacteristics = searchObjKeyVal(translation['LC_CharacteristicElements'],'Source_ElementDefinition',SubCharacteristicRef);
                                                                                                            let transSubCharacteristic = [];
                                                                                                            transSubCharacteristic = searchObjKeyVal(transSubCharacteristics,'Translation',SubCharacteristic[0]);
                                                                                                            if(transSubCharacteristic[0] !== undefined)
                                                                                                                SubCharacteristic[0] = transSubCharacteristic[0]['LChS_EquivalentElement'];
                                                                                                            if(typeof(SubCharacteristic[1]) === "object"){
                                                                                                                Object.entries(SubCharacteristic[1]).forEach((SubCharacteristic_obj_detail)=>{
                                                                                                                    buildSubCharacteristics = {...buildSubCharacteristics, [SubCharacteristic[0]]: [parseInt(SubCharacteristic_obj_detail[1]['min']),parseInt(SubCharacteristic_obj_detail[1]['max'])]};
                                                                                                                });
                                                                                                            }
                                                                                                            else
                                                                                                                buildSubCharacteristics = {...buildSubCharacteristics, [SubCharacteristic[0]]: SubCharacteristic[1]};
                                                                                                        }
                                                                                                    });
                                                                                                    Characteristics = [...Characteristics, buildSubCharacteristics];                                                                                                    
                                                                                                });
                                                                                            }
                                                                                        });
                                                                                        Characteristics = [...Characteristics, buildCharacteristics];
                                                                                    });
                                                                                }
                                                                            });
                                                                            Properties = [...Properties, buildProperties];
                                                                        });
                                                                    }
                                                                });
                                                                Strata = [...Strata, buildStrata];
                                                            });
                                                        }
                                                    });
                                                    HPs = [...HPs, buildHP];
                                                });
                                                LC_Characteristics.current = [...LC_Characteristics.current, ...Characteristics];
                                                stratumCharacteristicNumber.current = LC_Characteristics.current.length+1;
                                                LC_Properties.current = [...LC_Properties.current, ...Properties];
                                                stratumPropertyNumber.current = LC_Properties.current.length+1;
                                                LC_Strata.current = [...LC_Strata.current, ...Strata];
                                                strataNumber.current = MaxStratumID;
                                                LC_HorizontalPatterns.current = [...LC_HorizontalPatterns.current, ...HPs];
                                                horizontalPatternNumber.current = MaxHPID;
                                            }
                                        });
                                        classes.push(buildClass);
                                    });
                                    LC_Class.current = classes;
                                    classesNumber.current = MaxClassID;
                                }
                            });
                        });
                    }
                }
            });
        }
        else if(LegendType === "csv"){
            LC_Legend.current = {id: 1, legend_name: FileName, legend_description: 'Legend description', legend_author: FileName};
            LC_Class.current = [];
            LC_ClassCharacteristics.current = {};
            LC_HorizontalPatterns.current = [];
            horizontalPatternNumber.current = 1;
            LC_Strata.current = [];
            strataNumber.current = 1;
            LC_Properties.current = [];
            LC_Characteristics.current = [];
            let importedClasses = Papa.parse(Legend, {header: true});

            let header_translation = {};
            Object.keys(importedClasses.data[0]).map((header)=>{
                let header_reference = header;
                let TranslatedHeader = searchObjKeyVal(translation["LC_ClassElements"],'Translation',header);
                if(TranslatedHeader.length > 0)
                    header_reference = TranslatedHeader[0]['LChS_EquivalentElement'];
                if(header_reference !== header)
                    header_translation = {...header_translation, [header_reference]: header};
            });

            Object.values(importedClasses['data']).forEach((clss)=>{                
                if(clss['ID']){
                    Object.entries(header_translation).forEach((header)=>{
                        clss[header[0]] = clss[header[1] as any];
                    });
                    if(clss['ID'] && clss['Class Name'] && clss['Elements']){
                        LC_Class.current = [...(LC_Class.current || []), {legend_id: 1, class_id: parseInt(clss['ID']), class_name: clss['Class Name'], class_description: clss['Class Description'], class_map_code: clss['Class Code'], class_color_code: clss['Color Code(Hex)'] }];
                        classesNumber.current = LC_Class.current.length+1;
                        LC_HorizontalPatterns.current = [...(LC_HorizontalPatterns.current || []), { class_id: parseInt(clss['ID']), horizontal_pattern_id: horizontalPatternNumber.current, name: "Horizontal Pattern "+horizontalPatternNumber.current, description: "Class Horizontal Pattern", cover: [0,100], occurrence: [0,100], type: null  }];
                        LC_Strata.current = [...(LC_Strata.current || []), { HPID: horizontalPatternNumber.current, stratumID: strataNumber.current, name: "Stratum "+strataNumber.current, description: "Horizontal Pattern "+horizontalPatternNumber.current+" Stratum", presence_type: "Fixed", on_top: null }];        
                        let elements = clss['Elements'].split(';');
                        let refBlock = null;
                        let BlockID = null;
                        let BlockReference = null;
                        if(elements.length > 0){                        
                            elements.map((element)=>{                            
                                element = element.trim();
                                if(element != ""){
                                    let TranslatedElement = fuzzySearchObjKeyVal(translation["LC_Blocks"],'Translation',element);
                                    if(TranslatedElement.length > 0)
                                        element = TranslatedElement[0]['LChS_EquivalentElement'];
                                    if(element !== ""){
                                        refBlock = blocks.current["LC_Block"]?.filter(block => block.block_reference === element);
                                        if(refBlock[0] !== undefined){
                                            BlockID = refBlock[0]['block_id'];
                                            BlockReference = refBlock[0]['block_reference'];
                                            LC_Properties.current = [...(LC_Properties.current || []), { StratumID: strataNumber.current, BlockID: parseInt(BlockID), BlockReference: BlockReference }];
                                        }
                                        else
                                            errBlock.push(element);
                                    }
                                }
                            });
                        }
                        horizontalPatternNumber.current++;
                        strataNumber.current++;
                    }
                    else
                        errFormat.push(FileName);                        
                }
            });
        } else
            toast.current.show({ severity: 'info', summary: 'Legend type Error', detail: 'LChS only supports LChS, LCCS and CSV legends at the moment.' });
    
        errors = [...errors, {"Format Errors": errFormat}];
        errors = [...errors, {"Class Characteristic Errors": errClassCharacteristics}];
        errors = [...errors, {"Block Errors": errBlock}];
        errors = [...errors, {"Characteristic Errors": errCharacteristic}];
    
        let errorDisplay = "";                                    
        if(errors[0]['Format Errors'].length === 0 && errors[1]['Class Characteristic Errors'].length === 0 && errors[2]['Block Errors'].length === 0 && errors[3]['Characteristic Errors'].length === 0){
            toast.current.show({ severity: 'success', summary: 'Import Success', detail: 'The legend import was without issues.' });
        }                                    
        else{
            if(errors[0]['Format Errors'].length > 0){
                let tempError = "";
                errors[0]['Format Errors'].map((error)=>{
                    tempError = tempError+error+", ";
                });
                tempError = '\nIncompatible File Format: \n'+tempError+'\nDirectly add these.\n';
                toast.current.show({ severity: 'error', summary: 'File Format Error', detail: tempError });
                LCMLError.current = true;
                errorDisplay = errorDisplay+tempError;
            }
            if(errors[1]['Class Characteristic Errors'].length > 0){
                let tempError = "";
                errors[1]['Class Characteristic Errors'].map((error)=>{
                    tempError = tempError+error+", ";
                });
                tempError = '\nIncompatible Class Characteristics: \n'+tempError+'\nDirectly add these.\n';
                toast.current.show({ severity: 'error', summary: 'LCML Class Characteristic Error', detail: tempError });
                LCMLError.current = true;
                errorDisplay = errorDisplay+tempError;
            }
            if(errors[2]['Block Errors'].length > 0){
                let tempError = "";
                errors[2]['Block Errors'].map((error)=>{
                    tempError = tempError+error+", ";
                });
                tempError = '\nIncompatible Elements: \n'+tempError+'\nDirectly add these.\n';
                toast.current.show({ severity: 'error', summary: 'LCML Element Error', detail: tempError });
                LCMLError.current = true;
                errorDisplay = errorDisplay+tempError;
            }
            if(errors[3]['Characteristic Errors'].length > 0){
                let tempError = "";
                errors[3]['Characteristic Errors'].map((error)=>{
                    tempError = tempError+error+", ";
                });
                tempError = '\nIncompatible Characteristics: \n'+tempError+'\nDirectly add these.\n';
                toast.current.show({ severity: 'error', summary: 'LCML Characteristic Error', detail: tempError });
                LCMLError.current = true;
                errorDisplay = errorDisplay+tempError;
            }
            const element = document.createElement("a");
            const file = new Blob([errorDisplay], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = "LChS_Legend_Import_Issues.log";
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
        }
        let timeout = 2000;
        if(LCMLError.current)
            timeout = 4000;
        const timer = setTimeout(() => {
            setFileUploadVisible(false);
            setRerenderTrigger('Legend Import'+randomstring.generate(8));
        }, timeout);
    }

    const [run, setRun] = useState(true);  // Start the tour automatically
    const [stepIndex, setStepIndex] = useState(0);

    return (
        <div className="m-0 p-0 overflow-auto">
            {visibleGuide && <Guide setGuideVisible={setGuideVisible} />}
            <Toast ref={toast} />            
            <ConfirmDialog />
            <div className="card m-0 p-0 guide-menu" style={{ minHeight: '30px' }}>
                <MenuBar 
                    setLoginVisible={setLoginVisible} 
                    setGuide1Visible={setGuide1Visible}
                    setFileUploadVisible={setFileUploadVisible} 
                    
                    confirm={confirmNewLegend}

                    legendValid={legendValid}
                    setExportVisible={setExportVisible}
                    setGuideVisible={setGuideVisible}
                    />
            </div>
            <div className="card flex align-items-top m-0 p-0">
                <SubMenuBar
                    setFileUploadVisible={setFileUploadVisible} 
                    setGuide1Visible={setGuide1Visible}
                    Validator={Validator}
                    
                    blocks={blocks}
                    characteristics={characteristics}
                    elements={elements}
                    LC_Legend={LC_Legend}
                    LC_Class={LC_Class}
                    LC_ClassCharacteristics={LC_ClassCharacteristics}
                    LC_HorizontalPatterns={LC_HorizontalPatterns}
                    LC_Strata={LC_Strata}
                    LC_Properties={LC_Properties}
                    LC_Characteristics={LC_Characteristics}

                    confirm={confirmNewLegend}

                    legendValid={legendValid}
                    setExportVisible={setExportVisible}
                    setGuideVisible={setGuideVisible}
                    />
            </div>
            <Splitter className="card m-0 p-0 align-items-top" style={{ height: '91vh', minHeight: '600px' }}>
                <SplitterPanel className="flex align-items-top" size={32} style={{ overflow: 'auto' }}>
                    <TreeView 
                        UUID={UUID}
                        legend={legend}
                        searchObj={searchObj}
                        rerenderTrigger={rerenderTrigger}
                        setRerenderTrigger={setRerenderTrigger}

                        blocks={blocks}
                        elements={elements}
                        LC_Legend={LC_Legend}
                        LC_Class={LC_Class}
                        LC_ClassCharacteristics={LC_ClassCharacteristics}
                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                        LC_Strata={LC_Strata}
                        horizontalPatternNumber={horizontalPatternNumber}
                        strataNumber={strataNumber}
                        LC_Properties={LC_Properties}
                        stratumPropertyNumber={stratumPropertyNumber}

                        activeLCElement={activeLCElement}
                        setActiveLCElement={setActiveLCElement}                
                        />
                </SplitterPanel>
                <SplitterPanel size={68} style={{ overflow: 'auto' }}>
                    <Splitter style={{ height: '100%' }} layout="vertical">
                        <SplitterPanel className="flex align-items-center justify-content-center" size={70} minSize={60} style={{ overflow: 'auto' }}>
                            <Flow 
                                rerenderTrigger={rerenderTrigger}
                                translator={translator}
                                setRerenderTrigger={setRerenderTrigger}
                                blocks={blocks}
                                characteristicLookUp={characteristicLookUp}
                                characteristics={characteristics}
                                
                                LC_Legend={LC_Legend}
                                LC_Class={LC_Class}
                                LC_ClassCharacteristics={LC_ClassCharacteristics}
                                LC_HorizontalPatterns={LC_HorizontalPatterns}
                                LC_Strata={LC_Strata}
                                LC_Properties={LC_Properties}
                                LC_Characteristics={LC_Characteristics}
                                
                                legendValid={legendValid}

                                activeLCElement={activeLCElement}
                                setActiveLCElement={setActiveLCElement}

                                triggerFullExport={triggerFullExport}
                                setFullExport={setFullExport}
                                />
                        </SplitterPanel>
                        <SplitterPanel className="flex align-items-center justify-content-center"size={30} minSize={30} style={{ overflow: 'auto' }}>
                            <Splitter style={{ height: '100%', overflow: 'hidden' }}>
                                <SplitterPanel className="flex align-items-top justify-content-center guide-properties" size={50} minSize={30} style={{ overflow: 'auto', backgroundColor: 'var(--highlight-bg)' }}>
                                    <Properties
                                        UUID={UUID}
                                        legend={legend}
                                        rerenderTrigger={rerenderTrigger}
                                        setRerenderTrigger={setRerenderTrigger}
                                        
                                        blocks={blocks}
                                        elements={elements}
                                        options={options}
                                        
                                        LC_Legend={LC_Legend}
                                        LC_Class={LC_Class}
                                        classesNumber={classesNumber}
                                        LC_ClassCharacteristics={LC_ClassCharacteristics}
                                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                                        horizontalPatternNumber={horizontalPatternNumber}
                                        LC_Strata={LC_Strata}                                        
                                        strataNumber={strataNumber}

                                        LC_Properties={LC_Properties}
                                        stratumPropertyNumber={stratumPropertyNumber}
                                        LC_Characteristics={LC_Characteristics}
                                        stratumCharacteristicNumber={stratumCharacteristicNumber}

                                        activeLCElement={activeLCElement}
                                        setActiveLCElement={setActiveLCElement}

                                        displayFormElements={displayFormElements}
                                        control={control}
                                        errors={errors}
                                        handleSubmit={handleSubmit}
                                        getValues={getValues}
                                        fields={fields}
                                        append={append}
                                        remove={remove}
                                        move={move}
                                        insert={insert}
                                        setValue={setValue}
                                        watch={watch}
                                        reset={reset}

                                        LC_Objectfilter={LC_Objectfilter}

                                        formReconfig={formReconfig}
                                        />
                                </SplitterPanel>
                                <SplitterPanel className="flex align-items-top justify-content-center guide-characteristics" size={50} minSize={30} style={{ overflow: 'auto', backgroundColor: 'var(--highlight-bg)' }}>
                                    <Characteristics
                                        UUID={UUID}
                                        legend={legend}
                                        rerenderTrigger={rerenderTrigger}
                                        setRerenderTrigger={setRerenderTrigger}

                                        blocks={blocks}
                                        elements={elements}
                                        options={options}
                                        characteristics={characteristics}
                                        characteristicLookUp={characteristicLookUp}
                                        
                                        LC_Legend={LC_Legend}
                                        LC_Class={LC_Class}
                                        LC_ClassCharacteristics={LC_ClassCharacteristics}
                                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                                        LC_Strata={LC_Strata}
                                        
                                        LC_Properties={LC_Properties}
                                        stratumPropertyNumber={stratumPropertyNumber}
                                        LC_Characteristics={LC_Characteristics}
                                        stratumCharacteristicNumber={stratumCharacteristicNumber}

                                        activeLCElement={activeLCElement}
                                        setActiveLCElement={setActiveLCElement}

                                        displayFormElements={displayFormElements}
                                        control={control}
                                        errors={errors}
                                        unregister={unregister}
                                        handleSubmit={handleSubmit}
                                        getValues={getValues}
                                        setValue={setValue}
                                        watch={watch}
                                        reset={reset}

                                        LC_Objectfilter={LC_Objectfilter}

                                        OptionsBus={OptionsBus}
                                        formReconfig={formReconfig}
                                        />
                                </SplitterPanel>
                            </Splitter>
                        </SplitterPanel>
                    </Splitter>
                </SplitterPanel>
            </Splitter>

            <div className="card flex justify-content-center">
                <Dialog header="Login" visible={visibleLogin} maximizable style={{ width: '50vw' }} onHide={() => setLoginVisible(false)}>
                    <Login />
                </Dialog>
            </div>

            <div className="card flex justify-content-center">
                <Button icon="pi pi-eye-slash" label="<LCML />" onClick={() => setSideBarVisible(true)} style={{ position: 'absolute', bottom: 0, right: 0, height: '25px', backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)', padding: '5px', fontSize: 'xx-small' }} size="small" />     
                <Sidebar header={LCMLHeader} visible={visibleSideBar} onHide={() => setSideBarVisible(false)} icons={customIcons} className="p-sidebar-lg" >
                    <LCMLEditor
                        rerenderTrigger={rerenderTrigger}
                        blocks={blocks}
                        LC_Legend={LC_Legend}
                        LC_Class={LC_Class}
                        LC_ClassCharacteristics={LC_ClassCharacteristics}
                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                        LC_Strata={LC_Strata}
                        LC_Properties={LC_Properties}
                        LC_Characteristics={LC_Characteristics}

                        legendValid={legendValid}
                        />
                </Sidebar>         
            </div>

            <div className="card flex justify-content-center">
                <Dialog header={guideTransition(1)} maximizable visible={visibleGuide1} style={{ width: '50vw' }} onHide={() => {setGuide1Visible(false);setRerenderTrigger('Legend');}} >
                    <GuideLegendCreation 
                        UUID={UUID} 
                        legend={legend}
                        setRerenderTrigger={setRerenderTrigger}

                        setGuide1Visible={setGuide1Visible} 
                        setGuide2Visible={setGuide2Visible} 
                        
                        LC_Legend={LC_Legend}
                        />
                </Dialog>
                <Dialog header={guideTransition(2)} maximizable visible={visibleGuide2} style={{ width: '50vw' }} onHide={() => {setGuide2Visible(false);setRerenderTrigger('Class');}} >
                    <GuideLegendCreation2 
                        UUID={UUID}
                        legend={legend}
                        classCharacteristics={classCharacteristics}
                        setRerenderTrigger={setRerenderTrigger}

                        setGuide1Visible={setGuide1Visible} 
                        setGuide2Visible={setGuide2Visible} 
                        setGuide3Visible={setGuide3Visible}
                        
                        LC_Legend={LC_Legend}
                        LC_Class={LC_Class}
                        classesNumber={classesNumber}
                        LC_ClassCharacteristics={LC_ClassCharacteristics}
                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                        LC_Strata={LC_Strata}
                        horizontalPatternNumber={horizontalPatternNumber}
                        strataNumber={strataNumber}
                        LC_Properties={LC_Properties}
                        stratumPropertyNumber={stratumPropertyNumber}
                        LC_Characteristics={LC_Characteristics}
                        stratumCharacteristicNumber={stratumCharacteristicNumber}
                        
                        displayFormElements={displayFormElements}
                        control={control}
                        errors={errors}
                        register={register}
                        unregister={unregister}
                        handleSubmit={handleSubmit}
                        getValues={getValues}
                        setValue={setValue}
                        watch={watch}
                        reset={reset}

                        searchObjKeyVal={searchObjKeyVal}
                        OptionsBus={OptionsBus}
                        />
                </Dialog>
                <Dialog header={guideTransition(3)} maximizable visible={visibleGuide3} style={{ width: '50vw' }} onHide={() => {setGuide3Visible(false);setRerenderTrigger('Strata');}} >
                    <GuideLegendCreation3 
                        UUID={UUID}
                        setRerenderTrigger={setRerenderTrigger}

                        setGuide2Visible={setGuide2Visible} 
                        setGuide3Visible={setGuide3Visible} 
                        setGuide4Visible={setGuide4Visible}

                        LC_Class={LC_Class}
                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                        LC_Strata={LC_Strata}
                        horizontalPatternNumber={horizontalPatternNumber}
                        strataNumber={strataNumber}

                        LC_Properties={LC_Properties}
                        stratumPropertyNumber={stratumPropertyNumber}
                        LC_Characteristics={LC_Characteristics}
                        stratumCharacteristicNumber={stratumCharacteristicNumber}

                        OptionsBus={OptionsBus}
                        />
                </Dialog>
                <Dialog header={guideTransition(4)} maximizable visible={visibleGuide4} style={{ width: '50vw' }} onHide={() => {setGuide4Visible(false);setRerenderTrigger('Elements');}} >
                    <GuideLegendCreation4 
                        UUID={UUID}
                        setRerenderTrigger={setRerenderTrigger}

                        setGuide3Visible={setGuide3Visible} 
                        setGuide4Visible={setGuide4Visible}

                        blockLookUp={blockLookUp}
                        blocks={blocks}
                        characteristicLookUp={characteristicLookUp}
                        characteristics={characteristics}
                        elements={elements}

                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                        LC_Strata={LC_Strata}

                        LC_Properties={LC_Properties}
                        stratumPropertyNumber={stratumPropertyNumber}
                        LC_Characteristics={LC_Characteristics}
                        stratumCharacteristicNumber={stratumCharacteristicNumber}

                        OptionsBus={OptionsBus}
                        options={options}
                        />
                </Dialog>
            </div>
            <div className="card flex justify-content-center m-0 p-0">
                <Dialog header={LegendImportHeader} visible={visibleFileUpload} style={{ width: '50vw' }} onHide={() =>{setFileUploadVisible(false);setRerenderTrigger('Legend Import');}}>
                    <LegendLoader 
                        UUID={UUID}
                        translator={translator}
                        legend={legend}
                        searchObj={searchObj}
                        searchObjKeyVal={searchObjKeyVal}
                        setRerenderTrigger={setRerenderTrigger}

                        setFileUploadVisible={setFileUploadVisible}

                        blocks={blocks}
                        characteristics={characteristics}
                        characteristicLookUp={characteristicLookUp}
                        elements={elements}
                        LC_Legend={LC_Legend}
                        LC_Class={LC_Class}
                        classesNumber={classesNumber}
                        LC_ClassCharacteristics={LC_ClassCharacteristics}
                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                        LC_Strata={LC_Strata}
                        horizontalPatternNumber={horizontalPatternNumber}
                        strataNumber={strataNumber}
                        LC_Properties={LC_Properties}
                        stratumPropertyNumber={stratumPropertyNumber}
                        LC_Characteristics={LC_Characteristics}
                        stratumCharacteristicNumber={stratumCharacteristicNumber}

                        translateLegend={translateLegend}
                        />
                </Dialog>
                <Sidebar header={ExportHeader} visible={visibleExport} onHide={() => setExportVisible(false)} className="p-sidebar-s" >
                    <Export
                        UUID={UUID}
                        translator={translator}
                        Validator={Validator}
                        searchObj={searchObj}
                        searchObjKeyVal={searchObjKeyVal}

                        blocks={blocks}
                        characteristics={characteristics}
                        characteristicLookUp={characteristicLookUp} 
                        legend={legend}

                        LC_Legend={LC_Legend}
                        LC_Class={LC_Class}
                        LC_ClassCharacteristics={LC_ClassCharacteristics}
                        LC_HorizontalPatterns={LC_HorizontalPatterns}
                        LC_Strata={LC_Strata}
                        LC_Properties={LC_Properties}
                        LC_Characteristics={LC_Characteristics}

                        legendValid={legendValid}
                        setExportVisible={setExportVisible}
                        setFullExport={setFullExport}
                        />
                </Sidebar>

            </div>
        </div>
    );
}