export const Rules = {
    SEEA: [
        {
            id: 2,
            name: "Herbaceous crops",
            priority: 1,
            syntax: "Ms'Herbaceous growth forms, cultivated and managed vegetation'",
            assignment: {class_id: 11, class_name: "Herbaceous crops", class_code: "SEEA 2"},
            inclusive: {
                elements: [                    
                    {
                        id: 1,
                        priority: 2,
                        element: 1007,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100021,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "CharacteristicReference", lower_bound: "LC_CultivatedAndManagedVegetationCharacteristics", lower_tolerance: "", upper_bound: "LC_CultivatedAndManagedVegetationCharacteristics", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: "="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",         
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 7,
            name: "Mangroves",
            priority: 1,
            syntax: "L1Ms'Woody growth forms, cover 10 100%, natural seminatural' + L2 Ms'Water body natural, brackish'",
            assignment: {class_id: 40, class_name: "Mangroves", class_code: "SEEA 7"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1004,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100020,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "vegetationArtificiality", lower_bound: "Natural or Seminatural", lower_tolerance: "", upper_bound: "Natural or Seminatural", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 10, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 2,
                        priority: 1,
                        element: 1038,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100001,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "waterSalinity", lower_bound: "Brackish", lower_tolerance: "", upper_bound: "Brackish", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }           
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 2, lower_tolerance: 0, upper_bound: 2, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 8,
            name: "Shrub covered areas",
            priority: 1,
            syntax: "L1Ms'Shrubs, cover 10 100%, natural seminatural' + EX L2Ms'Trees, cover 10 100%'",
            assignment: {class_id: 49, class_name: "Shrub covered areas", class_code: "SEEA 8"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1006,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100020,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "vegetationArtificiality", lower_bound: "Natural or Seminatural", lower_tolerance: "", upper_bound: "Natural or Seminatural", upper_tolerance: "", not: false, mode: "="},
                                            {object: "name", lower_bound: "Natural Or Seminatural Vegetation", lower_tolerance: "", upper_bound: "Natural Or Seminatural Vegetation", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 10, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }                    
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                    /*{
                        id: 1,
                        priority: 1,
                        element: 1005,
                        ruleDefinition: {
                            characteristics: [                               
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 10, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }*/
                ],
                strata: {
                    /*id: 1,
                    priority: 1,
                    name: "Strata Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }*/
                },
                horizontalPatterns: {
                    /*id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }*/
                },
                classes: {
                    /*id: 1,
                    priority: 1,
                    name: "Class Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }*/
                }
            }
        },
        {
            id: 5,
            name: "Grassland",
            priority: 1,
            syntax: "Ms'Herbaceous growth forms, natural vegetation'",
            assignment: {class_id: 32, class_name: "Grassland", class_code: "SEEA 5"},
            inclusive: {
                elements: [                    
                    {
                        id: 1,
                        priority: 2,
                        element: 1007,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 2,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100020,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "vegetationArtificiality", lower_bound: "Natural or Seminatural", lower_tolerance: "", upper_bound: "Natural or Seminatural", upper_tolerance: "", not: false, mode: "="},
                                            {object: "name", lower_bound: "Natural Or Seminatural Vegetation", lower_tolerance: "", upper_bound: "Natural Or Seminatural Vegetation", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 10,
            name: "Sparsely natural vegetated areas",
            priority: 1,
            syntax: "Ms'Woody growth forms, cover 2 9%, natural or seminatural'",
            assignment: {class_id: 80, class_name: "Sparsely natural vegetated areas", class_code: "SEEA 10"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1004,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Vegetation Artificiality Test",
                                    priority: 1,
                                    characteristicID: 100020,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "vegetationArtificiality", lower_bound: "Natural or Seminatural", lower_tolerance: "", upper_bound: "Natural or Seminatural", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 2, lower_tolerance: -1, upper_bound: 9, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }                   
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 4,
            name: "Multiple or layered crops",
            priority: 1,
            syntax: "L1Ms'Woody growth forms' + L2Ms'Herbaceous vegetation cultivated and managed'",
            assignment: {class_id: 22, class_name: "Multiple or layered crops", class_code: "SEEA 4"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 2,
                        element: 1004,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100021,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "CharacteristicReference", lower_bound: "LC_CultivatedAndManagedVegetationCharacteristics", lower_tolerance: "", upper_bound: "LC_CultivatedAndManagedVegetationCharacteristics", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                },
                                {
                                    id: 2,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100020,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "vegetationArtificiality", lower_bound: "Natural or Seminatural", lower_tolerance: "", upper_bound: "Natural or Seminatural", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1007,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100021,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "CharacteristicReference", lower_bound: "LC_CultivatedAndManagedVegetationCharacteristics", lower_tolerance: "", upper_bound: "LC_CultivatedAndManagedVegetationCharacteristics", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                },
                                {
                                    id: 2,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100020,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "vegetationArtificiality", lower_bound: "Natural or Seminatural", lower_tolerance: "", upper_bound: "Natural or Seminatural", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 2, lower_tolerance: 0, upper_bound: 2, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 9,
            name: "Herbaceous vegetation acquatic or regularly flooded",
            priority: 1,
            syntax: "L1Ms'Herbaceous growth forms, cover 10 100%' + L2Ms'Water body, natural, brackish/saline' + EX L3Ms'Woody growth forms, cover 10 100%'",
            assignment: {class_id: 63, class_name: "Herbaceous vegetation acquatic or regularly flooded", class_code: "SEEA 9"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1007,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 10, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1038,
                        ruleDefinition: {
                            characteristics: [
                                /*{
                                    id: 1,
                                    name: "Characteristic Artificiality Test",
                                    priority: 1,
                                    characteristicID: 100003,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "artificiality", lower_bound: "Natural", lower_tolerance: "", upper_bound: "Natural", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                },
                                {
                                    id: 1,
                                    name: "Characteristic Salinity Test",
                                    priority: 1,
                                    characteristicID: 100001,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "waterSalinity", lower_bound: "Brackish", lower_tolerance: "", upper_bound: "Brackish", upper_tolerance: "", not: false, mode: "="},
                                            {object: "waterSalinity", lower_bound: "Saline", lower_tolerance: "", upper_bound: "Saline", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }*/
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 10, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 2, lower_tolerance: 0, upper_bound: 2, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1004,
                        ruleDefinition: {
                            characteristics: [                               
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 10, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }                    
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            }
        },
        {
            id: 6,
            name: "Tree covered areas",
            priority: 1,
            syntax: "Ms'Trees cover 10 100%'",
            assignment: {class_id: 115, class_name: "Tree covered areas", class_code: "SEEA 6"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1005,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 10, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }                   
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 11,
            name: "Terrestrial barren land",
            priority: 1,
            syntax: "L1Ms'Natural surface' + EX L2Ms'Growth forms, cover 3 100%'",
            assignment: {class_id: 87, class_name: "Terrestrial barren land", class_code: "SEEA 11"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1024,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1026,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1027,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1028,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1029,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1030,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1031,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1032,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1033,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1034,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1035,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                    ,
                    {
                        id: 1,
                        priority: 1,
                        element: 1036,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1003,
                        ruleDefinition: {
                            characteristics: [                               
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [                                            
                                            {object: "cover", lower_bound: 3, lower_tolerance: 0, upper_bound: 100, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }                    
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            }
        },
        {
            id: 12,
            name: "Permanent snow and glaciers",
            priority: 1,
            syntax: "Ms'Snow/Ice'",
            assignment: {class_id: 96, class_name: "Permanent snow and glaciers", class_code: "SEEA 12"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1039,
                        ruleDefinition: {
                            characteristics: [                                
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1040,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: "="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",         
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 13,
            name: "Inland water bodies",
            priority: 1,
            syntax: "Ms'Water body, natural, persistence period 9 11 months'",
            assignment: {class_id: 103, class_name: "Inland water bodies", class_code: "SEEA 13"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1038,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Artificiality Test",
                                    priority: 1,
                                    characteristicID: 100003,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "artificiality", lower_bound: "Natural", lower_tolerance: "", upper_bound: "Natural", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="},
                                            {object: "persistenceUnits", lower_bound: "Month", lower_tolerance: "", upper_bound: "Month", upper_tolerance: "", not: false, mode: "="},
                                            {object: "persistencePeriod", lower_bound: 9, lower_tolerance: 0, upper_bound: 11, upper_tolerance: 0, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1040,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: "="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",         
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },
        {
            id: 1,
            name: "Artificial surfaces",
            priority: 1,
            syntax: "Msi'Artificial Surface'",
            assignment: {class_id: 7, class_name: "Artificial surfaces", class_code: "SEEA 1"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1014,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1015,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1016,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1017,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1018,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1019,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1020,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1021,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1022,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 2,
                        element: 1023,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: "="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",         
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        },       
        {
            id: 3,
            name: "Woody crops",
            priority: 1,
            syntax: "Ms'Woody growth forms, cultivated and managed vegetation'",
            assignment: {class_id: 16, class_name: "Woody crops", class_code: "SEEA 3"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 2,
                        element: 1004,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Test",
                                    priority: 1,
                                    characteristicID: 100021,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "CharacteristicReference", lower_bound: "LC_CultivatedAndManagedVegetationCharacteristics", lower_tolerance: "", upper_bound: "LC_CultivatedAndManagedVegetationCharacteristics", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: "="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        }/*,
        {
            id: 14,
            name: "Costal waterbodies and intertidal areas",
            priority: 1,
            syntax: "Ms'Water body, geographical aspect coastal areas, natural'",
            assignment: {class_id: 108, class_name: "Costal waterbodies and intertidal areas", class_code: "SEEA 14"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1038,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Characteristic Artificiality Test",
                                    priority: 1,
                                    characteristicID: 100003,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "artificiality", lower_bound: "Natural", lower_tolerance: "", upper_bound: "Natural", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    },
                    {
                        id: 1,
                        priority: 1,
                        element: 1040,
                        ruleDefinition: {
                            characteristics: [
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Presence Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: "="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",         
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"}
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                ],
                strata: {
                },
                horizontalPatterns: {
                },
                classes: {
                }
            }
        }*/
    ],
    Template: [
        {
            id: 1,
            name: "Artificial Surface",
            priority: 1,
            syntax: "Msi'Artificial Surface'",
            assignment: {class_id: 7, class_name: "Artificial Surface", class_code: "SEEA 1"},
            inclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1004,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Species Test",
                                    priority: 1,
                                    characteristicID: 100010,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "speciesName", lower_bound: "Apple", lower_tolerance: "", upper_bound: "Apple", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Cover & Height Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "BlockID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="},
                                            {object: "cover", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="},
                                            {object: "height", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                                        ]
                                    }
                                },
                                {
                                    id: 2,
                                    name: "Depth Test",
                                    priority: 2,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "depth", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "stratumID", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                            {object: "cover", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: "="},
                            {object: "occurrence", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "horizontal_pattern_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                            {object: "cover", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: "="},
                            {object: "occurrence", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Inclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_id", lower_bound: 0, lower_tolerance: 0, upper_bound: 0, upper_tolerance: 0, not: false, mode: ">"},
                            {object: "class_name", lower_bound: "Class 2", lower_tolerance: "Class 2", upper_bound: "Class 2", upper_tolerance: "Class 2", not: false, mode: "="},
                            {object: "class_map_code", lower_bound: "LCR1", lower_tolerance: "LCR1", upper_bound: "LCR1", upper_tolerance: "LCR1", not: false, mode: "="},
                            {object: "class_color_code", lower_bound: "FF0000", lower_tolerance: "FF0000", upper_bound: "FF0000", upper_tolerance: "FF0000", not: false, mode: "="}                          
                        ]
                    }
                }
            },
            exclusive: {
                elements: [
                    {
                        id: 1,
                        priority: 1,
                        element: 1001,
                        ruleDefinition: {
                            characteristics: [
                                {
                                    id: 1,
                                    name: "Species Test",
                                    priority: 1,
                                    characteristicID: 100010,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "speciesName", lower_bound: "Apple", lower_tolerance: "", upper_bound: "Apple", upper_tolerance: "", not: false, mode: "="}
                                        ]
                                    }
                                }
                            ],
                            properties: [
                                {
                                    id: 1,
                                    name: "Cover & Height Test",
                                    priority: 1,
                                    rules: 
                                    {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "elementPresenceType", lower_bound: "Fixed", lower_tolerance: "", upper_bound: "Fixed", upper_tolerance: "", not: false, mode: "="},
                                            {object: "elementPresenceType", lower_bound: "Mandatory", lower_tolerance: "", upper_bound: "Mandatory", upper_tolerance: "", not: false, mode: "="},
                                            {object: "cover", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="},
                                            {object: "height", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                                        ]
                                    }
                                },
                                {
                                    id: 2,
                                    name: "Depth Test",
                                    priority: 2,
                                    rules: {
                                        properties: [
                                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                                        ],
                                        characteristics: [
                                            {object: "depth", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ],
                strata: {
                    id: 1,
                    priority: 1,
                    name: "Strata Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "cover", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: "="},
                            {object: "occurrence", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                        ]
                    }
                },
                horizontalPatterns: {
                    id: 1,
                    priority: 1,
                    name: "Horizontal Pattern Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "cover", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: "="},
                            {object: "occurrence", lower_bound: 0, lower_tolerance: -10, upper_bound: 100, upper_tolerance: 10, not: false, mode: ">=<="}
                        ]
                    }
                },
                classes: {
                    id: 1,
                    priority: 1,
                    name: "Class Exclusion",
                    rules: {
                        properties: [
                            {object: "sum", lower_bound: 1, lower_tolerance: 0, upper_bound: 1, upper_tolerance: 0, not: false, mode: ">="}
                        ],
                        characteristics: [
                            {object: "class_name", lower_bound: "Class 1", lower_tolerance: "", upper_bound: "Class 1", upper_tolerance: "", not: false, mode: "="},
                        ]
                    }
                }
            }
        }
    ]
};

export const LegendClasses = {
    SEEA: [
        {
            class_id: 7,    
            class_name: "Artificial surfaces",     
            class_map_code: "SEEA 1"
        },
        {     
            class_id: 11,
            class_name: "Herbaceous crops",
            class_map_code: "SEEA 2"
        },            
        {
            class_id: 16,
            class_name: "Woody crops",
            class_map_code: "SEEA 3"
        },      
        {
            class_id: 22,
            class_name: "Multiple or layered crops",
            class_map_code: "SEEA 4"
        },      
        {
            class_id: 32,
            class_name: "Grassland",
            class_map_code: "SEEA 5"
        },      
        {
            class_id: 115,
            class_name: "Tree covered areas",
            class_map_code: "SEEA 6"
        },      
        {
            class_id: 40,
            class_name: "Mangroves",
            class_map_code: "SEEA 7"
        },      
        {
            class_id: 49,
            class_name: "Shrub covered areas",
            class_map_code: "SEEA 8"
        },      
        {
            class_id: 63,
            class_name: "Herbaceous vegetation acquatic or regularly flooded",
            class_map_code: "SEEA 9"
        },      
        {
            class_id: 80,
            class_name: "Sparsely natural vegetated areas",
            class_map_code: "SEEA 10"
        },      
        {
            class_id: 87,
            class_name: "Terrestrial barren land",
            class_map_code: "SEEA 11"
        },            
        {
            class_id: 96,
            class_name: "Permanent snow and glaciers",
            class_map_code: "SEEA 12"
        },            
        {
            class_id: 103,
            class_name: "Inland water bodies",
            class_map_code: "SEEA 13"
        },            
        {
            class_id: 108,
            class_name: "Costal waterbodies and intertidal areas",
            class_map_code: "SEEA 14"
        }
    ]
}