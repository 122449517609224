const processProperties = (subject) => {
    let sum = subject.length;
    let result = {
      sum: sum
    };
    return(
      result
    );
}
export const processRules = (subjectGroup,rules) => {
    //console.log(rules);

    let result = {};
    let propertiesResult = [];
    let characteristicsResult = [];
    let properties = processProperties(subjectGroup);
    if(rules !== undefined){
        rules.properties?.map((rule,index)=>{
            let execution = null;
            //console.log(properties);
            //console.log(rule);
            let lower_tolerance = rule.lower_bound+rule.lower_tolerance;
            //console.log(lower_tolerance);
            let upper_tolerance = rule.upper_bound+rule.upper_tolerance;
            //console.log(upper_tolerance);
            if(rule.mode === "="){
                if(typeof(properties[rule.object]) === 'object'){
                    execution = ((properties[rule.object][0] === rule.lower_bound || properties[rule.object][0] === lower_tolerance) && (properties[rule.object][1] === rule.upper_bound || properties[rule.object][1] === upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = (properties[rule.object] === rule.lower_bound || properties[rule.object] === lower_tolerance || properties[rule.object] === rule.upper_bound || properties[rule.object] === upper_tolerance);
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === ">"){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] > rule.lower_bound || properties[rule.object][0] > lower_tolerance) && (properties[rule.object][1] > rule.upper_bound || properties[rule.object][1] > upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = (properties[rule.object] > rule.lower_bound || properties[rule.object] > lower_tolerance || properties[rule.object] > rule.upper_bound || properties[rule.object] > upper_tolerance);
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "<"){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] < rule.lower_bound || properties[rule.object][0] < lower_tolerance) && (properties[rule.object][1] < rule.upper_bound || properties[rule.object][1] < upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = (properties[rule.object] < rule.lower_bound || properties[rule.object] < lower_tolerance || properties[rule.object] < rule.upper_bound || properties[rule.object] < upper_tolerance);
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }        
            else if(rule.mode === "<="){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] <= rule.lower_bound || properties[rule.object][0] <= lower_tolerance) && (properties[rule.object][1] <= rule.upper_bound || properties[rule.object][1] <= upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = (properties[rule.object] <= rule.lower_bound || properties[rule.object] <= lower_tolerance || properties[rule.object] <= rule.upper_bound || properties[rule.object] <= upper_tolerance);
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === ">="){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] >= rule.lower_bound || properties[rule.object][0] >= lower_tolerance) && (properties[rule.object][1] >= rule.upper_bound || properties[rule.object][1] >= upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = (properties[rule.object] >= rule.lower_bound || properties[rule.object] >= lower_tolerance || properties[rule.object] >= rule.upper_bound || properties[rule.object] >= upper_tolerance);
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "><"){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] > rule.lower_bound || properties[rule.object][0] > lower_tolerance) && (properties[rule.object][1] < rule.upper_bound || properties[rule.object][1] < upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = ((properties[rule.object] > rule.lower_bound || properties[rule.object] > lower_tolerance) && (properties[rule.object] < rule.upper_bound || properties[rule.object] < upper_tolerance));
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "<>"){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] < rule.lower_bound || properties[rule.object][0] < lower_tolerance) && (properties[rule.object][1] > rule.upper_bound || properties[rule.object][1] > upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = ((properties[rule.object] < rule.lower_bound || properties[rule.object] < lower_tolerance) && (properties[rule.object] > rule.upper_bound || properties[rule.object] > upper_tolerance));
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === ">=<="){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] >= rule.lower_bound || properties[rule.object][0] >= lower_tolerance) && (properties[rule.object][1] <= rule.upper_bound || properties[rule.object][1] <= upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = ((properties[rule.object] >= rule.lower_bound || properties[rule.object] >= lower_tolerance) && (properties[rule.object] <= rule.upper_bound || properties[rule.object] <= upper_tolerance));
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "<=>="){
                //console.log(typeof(properties[rule.object]));
                if(typeof(properties[rule.object]) === 'object'){          
                    execution = ((properties[rule.object][0] <= rule.lower_bound || properties[rule.object][0] <= lower_tolerance) && (properties[rule.object][1] >= rule.upper_bound || properties[rule.object][1] >= upper_tolerance));
                    //console.log(properties[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+properties[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                    execution = ((properties[rule.object] <= rule.lower_bound || properties[rule.object] <= lower_tolerance) && (properties[rule.object] >= rule.upper_bound || properties[rule.object] >= upper_tolerance));
                    //console.log(properties[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            if(execution)
                propertiesResult = [...propertiesResult, index];
            //console.log(propertiesResult);
        });

        rules.characteristics?.map((rule,index)=>{
            subjectGroup.map((subject)=>{
            let execution = null;
            //console.log(subject);
            //console.log(rule);
            let lower_tolerance = rule.lower_bound+rule.lower_tolerance;
            //console.log(lower_tolerance);
            let upper_tolerance = rule.upper_bound+rule.upper_tolerance;
            //console.log(upper_tolerance);
            if(rule.mode === "="){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] === rule.lower_bound || subject[rule.object][0] === lower_tolerance) && (subject[rule.object][1] === rule.upper_bound || subject[rule.object][1] === upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = (subject[rule.object] === rule.lower_bound || subject[rule.object] === lower_tolerance || subject[rule.object] === rule.upper_bound || subject[rule.object] === upper_tolerance);
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === ">"){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] > rule.lower_bound || subject[rule.object][0] > lower_tolerance) && (subject[rule.object][1] > rule.upper_bound || subject[rule.object][1] > upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = (subject[rule.object] > rule.lower_bound || subject[rule.object] > lower_tolerance || subject[rule.object] > rule.upper_bound || subject[rule.object] > upper_tolerance);
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "<"){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] < rule.lower_bound || subject[rule.object][0] < lower_tolerance) && (subject[rule.object][1] < rule.upper_bound || subject[rule.object][1] < upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = (subject[rule.object] < rule.lower_bound || subject[rule.object] < lower_tolerance || subject[rule.object] < rule.upper_bound || subject[rule.object] < upper_tolerance);
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }        
            else if(rule.mode === "<="){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] <= rule.lower_bound || subject[rule.object][0] <= lower_tolerance) && (subject[rule.object][1] <= rule.upper_bound || subject[rule.object][1] <= upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = (subject[rule.object] <= rule.lower_bound || subject[rule.object] <= lower_tolerance || subject[rule.object] <= rule.upper_bound || subject[rule.object] <= upper_tolerance);
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === ">="){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] >= rule.lower_bound || subject[rule.object][0] >= lower_tolerance) && (subject[rule.object][1] >= rule.upper_bound || subject[rule.object][1] >= upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = (subject[rule.object] >= rule.lower_bound || subject[rule.object] >= lower_tolerance || subject[rule.object] >= rule.upper_bound || subject[rule.object] >= upper_tolerance);
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "><"){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] > rule.lower_bound || subject[rule.object][0] > lower_tolerance) && (subject[rule.object][1] < rule.upper_bound || subject[rule.object][1] < upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = ((subject[rule.object] > rule.lower_bound || subject[rule.object] > lower_tolerance) && (subject[rule.object] < rule.upper_bound || subject[rule.object] < upper_tolerance));
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "<>"){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] < rule.lower_bound || subject[rule.object][0] < lower_tolerance) && (subject[rule.object][1] > rule.upper_bound || subject[rule.object][1] > upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = ((subject[rule.object] < rule.lower_bound || subject[rule.object] < lower_tolerance) && (subject[rule.object] > rule.upper_bound || subject[rule.object] > upper_tolerance));
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === ">=<="){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] >= rule.lower_bound || subject[rule.object][0] >= lower_tolerance) && (subject[rule.object][1] <= rule.upper_bound || subject[rule.object][1] <= upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = ((subject[rule.object] >= rule.lower_bound || subject[rule.object] >= lower_tolerance) && (subject[rule.object] <= rule.upper_bound || subject[rule.object] <= upper_tolerance));
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            else if(rule.mode === "<=>="){
                //console.log(typeof(subject[rule.object]));
                if(typeof(subject[rule.object]) === 'object'){          
                execution = ((subject[rule.object][0] <= rule.lower_bound || subject[rule.object][0] <= lower_tolerance) && (subject[rule.object][1] >= rule.upper_bound || subject[rule.object][1] >= upper_tolerance));
                //console.log(subject[rule.object][0]+" Lower:"+rule.lower_bound+" ["+rule.lower_tolerance+"] "+subject[rule.object][1]+" Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
                else{
                execution = ((subject[rule.object] <= rule.lower_bound || subject[rule.object] <= lower_tolerance) && (subject[rule.object] >= rule.upper_bound || subject[rule.object] >= upper_tolerance));
                //console.log(subject[rule.object]+" Lower:"+rule.lower_bound+" ["+lower_tolerance+"] Upper:"+rule.upper_bound+" ["+upper_tolerance+"]");
                }
            }
            //console.log(execution);
            if(execution)
                characteristicsResult = [...characteristicsResult, index];
            //console.log(characteristicsResult);
            });        
        });
    }

    characteristicsResult = [...new Set(characteristicsResult)];
    propertiesResult = [...new Set(propertiesResult)];
    result = {properties: propertiesResult, characteristics: characteristicsResult};

    return(
        result
    );
}

export const processElementRules = (propertiesSubjectGroup,characteristicsSubjectGroup,elementRules) => {
    let elementRulesResult = [];
    /*console.log(propertiesSubjectGroup);
    console.log(characteristicsSubjectGroup);
    console.log(elementRules);*/
    elementRules.map((elementRule)=>{
        //console.log(elementRule)      
        let matchingElements = propertiesSubjectGroup.filter( subject => subject.BlockID === elementRule.element );
        //console.log(matchingElements);
        matchingElements.map((element) => {
            //console.log(element);
            let propertyRules = null;        
            elementRule.ruleDefinition.properties.map((propertyRule) =>{
                //console.log(propertyRule);
                let matchProperties = processRules([element],propertyRule.rules);
                //console.log(matchProperties);
                if(matchProperties['properties'].length > 0){
                    if(matchProperties['characteristics'].length > 0){
                        propertyRules = matchProperties['characteristics'];
                    }
                }
            });
            let matchingCharacteristics = characteristicsSubjectGroup.filter( subject => subject.BlockID === elementRule.element );
            //console.log(matchingCharacteristics);
            let characteristicRules = null;
            elementRule.ruleDefinition.characteristics.map((characteristicRule) =>{
                //console.log(characteristicRule);
                let matchCharacteristics = processRules(matchingCharacteristics,characteristicRule.rules);
                //console.log(matchCharacteristics);
                if(matchCharacteristics['properties'].length > 0){
                    if(matchCharacteristics['characteristics'].length > 0){
                        characteristicRules = matchCharacteristics['characteristics'];
                    }
                }
            });
            /*console.log(propertyRules);
            console.log(characteristicRules);*/
            if(propertyRules){
                if(elementRule.ruleDefinition.characteristics.length > 0){
                    if(characteristicRules)
                        elementRulesResult.push({rule: elementRule.id, properties: propertyRules, characteristics: characteristicRules});
                }
                else
                    elementRulesResult.push({rule: elementRule.id, properties: propertyRules, characteristics: characteristicRules});
            }
        });
    });
    //console.log(elementRulesResult);
    return(
        elementRulesResult
    )
}