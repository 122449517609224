
import React, { useEffect, useState, useRef } from 'react';
import { ProcessLegend } from './ProcessLegend';
import { LegendClasses } from './RuleBuilder/ConnectionRules';
import { DataTable, DataTableRowEditCompleteEvent } from 'primereact/datatable';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

interface Product {
    id: string;
    code: string;
    name: string;
    description: string;
    image: string;
    price: number;
    category: string;
    quantity: number;
    inventoryStatus: string;
    rating: number;
}

export const Connector = (props) => {
    const {UUID,
        translator,
        legendTemplate,
        options,        
        blocks,
        blockLookUp,
        characteristics,
        characteristicLookUp,
        referenceLegend,
        setReferenceLegend,
        fileContent,
        setLegendUploadVisible,
        setConnectorVisible
        } = props;

    let reference = Object.entries(fileContent[0]);
    fileContent.forEach((file,index)=>{
        let filename = Object.keys(file);
        if (/reference/i.test(filename[0]))
            reference = Object.entries(fileContent[index]);
    });
    let baseHeaderTitle = reference[0][0];
    let baseHeaderClasses = reference[0][1]['LCT_Class'];
    let baseHPs = reference[0][1]['LCT_HorizontalPatterns'];
    let baseStrata = reference[0][1]['LCT_Strata'];
    let baseProperties = reference[0][1]['LCT_Properties'];
    let baseElements = {};
    let rowColumns = [];
    let colHeader = [];

    let referenceClasses = [];
    baseHeaderClasses.map((clss)=>{
        referenceClasses.push(clss['class_name']);
        //colHeader.push(<Column header={clss['class_name']} colSpan={2} />);
        rowColumns.push(<Column key={"E"+clss['class_id']} header={"Matching Elements"} sortable filter field={"ClassElements"+clss['class_id']} />);
        rowColumns.push(<Column key={"P"+clss['class_id']} header={"Similarity"} field={"ClassPercentage"+clss['class_id']} />);
        let elements = [];
        let filterd_HPs = baseHPs.filter( HP => HP.class_id === clss['class_id'] );
        filterd_HPs.map((HP)=>{
            let filterd_Strata = baseStrata.filter( Strata => Strata.HPID === HP['horizontal_pattern_id'] );
            filterd_Strata.map((Strata)=>{
                let filterd_Properties = baseProperties.filter( Properties => Properties.StratumID === Strata['stratumID'] );
                filterd_Properties.map((Properties)=>{
                    elements.push(Properties['BlockID']);
                });
            });
        });
        baseElements = {...baseElements, [clss['class_id']]: elements};
    });
    
    let appointedClasses = [];
    let legend = null;
    fileContent.map((file)=>{
        let subject = Object.entries(file);            
        appointedClasses.push(subject[0][1]['LCT_Class']);
        legend = subject[0][1];
        //let matches = ProcessLegend(legend,referenceLegend.ref);
        //console.log(matches);
    });

    let appointedClassesMatches = [];

    appointedClasses[0].map((appointedClass)=>{
        //console.log(appointedClass);
        let tempLegend = {};
        let filterd_classes = legend.LCT_Class.filter( clss1 => clss1.class_id === appointedClass.class_id);
        //console.log(filterd_classes);
        tempLegend = {...tempLegend, LCT_Class: filterd_classes};
        let filterd_HPs = legend.LCT_HorizontalPatterns.filter( HP => HP.class_id === appointedClass.class_id );        
        tempLegend = {...tempLegend, LCT_HorizontalPatterns: filterd_HPs};
        //console.log(filterd_HPs);
        if(filterd_HPs != undefined){
            Object.keys(filterd_HPs).forEach((key1) => {
                let filterd_strata = legend.LCT_Strata.filter( stratum => stratum.HPID === filterd_HPs[key1]["horizontal_pattern_id"] );
                //console.log(filterd_strata);
                tempLegend = {...tempLegend, LCT_Strata: filterd_strata};
                if(filterd_strata !== undefined){
                    let LCT_Properties = [];
                    Object.keys(filterd_strata).forEach((key2) => {
                        let filterd_elements = legend.LCT_Properties.filter( properties => properties.StratumID === filterd_strata[key2]["stratumID"] );
                        //console.log(filterd_elements);
                        LCT_Properties.push(...filterd_elements);
                        tempLegend = {...tempLegend, LCT_Properties: LCT_Properties};
                        let LCT_Characteristics = [];
                        Object.keys(filterd_elements).forEach((key3) => {
                            let filterd_blocks = blocks?.current["LC_Block"].filter( block => block.block_id === filterd_elements[key3]["BlockID"] );
                            //console.log(filterd_blocks);
                            //console.log(legend.LCT_Characteristics);
                            let filterd_xteristics = legend.LCT_Characteristics.filter( characteristics => characteristics.StratumID === filterd_strata[key2]["stratumID"] && characteristics.BlockID === filterd_blocks[0]["block_id"] );
                            //console.log(filterd_xteristics);
                            LCT_Characteristics.push(...filterd_xteristics);
                            tempLegend = {...tempLegend, LCT_Characteristics: LCT_Characteristics};
                        });
                    });
                }
            });
        }
        //console.log(tempLegend);
        let matches = ProcessLegend(tempLegend,referenceLegend.ref);
        //console.log(matches);
        if(matches.length > 0){
            //appointedClassesMatches.push({ class_map_code: appointedClass.class_map_code, class_name: appointedClass.class_name, reference_class: matches[0].assignment.class_code, matching_rules: [matches[0].rule_id,": ",matches[0].rule_name,"[",matches[0].rule_syntax,"]=",matches[0].rule_priority] });
            appointedClassesMatches.push({ class_map_code: appointedClass.class_map_code, class_name: appointedClass.class_name, reference_class: [matches[0].assignment.class_code,matches[0].assignment.class_name], matching_rules: ["[",matches[0].rule_syntax,"]"] });
        }
        else
            appointedClassesMatches.push({ class_map_code: appointedClass.class_map_code, class_name: appointedClass.class_name, reference_class: "No Match", matching_rules: "No Match" });
    });

    const dt = useRef(null);
    const [products, setProducts] = useState<Product[]>(appointedClassesMatches);
    const [statuses] = useState<string[]>(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);

    const getSeverity = (value: string) => {
        switch (value) {
            case 'Artificial surfaces':
                return 'success';

            case 'Herbaceous crops':
                return 'warning';

            case 'Woody crops':
                return 'danger';

            default:
                return null;
        }
    };

    const onRowEditComplete = (e: DataTableRowEditCompleteEvent) => {
        let _products = [...products];
        let { newData, index } = e;

        _products[index] = newData as Product;

        setProducts(_products);
    };

    const textEditor = (options: ColumnEditorOptions) => {
        return <InputText type="text" value={options.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback!(e.target.value)} />;
    };    
    
    let displayClasses = [];
    Object.values(LegendClasses[referenceLegend.ref].map((clss)=>{
        displayClasses.push([clss.class_map_code,clss.class_name]);
    }));
    const statusEditor = (options: ColumnEditorOptions) => {
        return (
            <Dropdown
                value={options.value}
                options={displayClasses}
                onChange={(e: DropdownChangeEvent) => options.editorCallback!(e.value)}
                placeholder="Select a Reference Class"
                itemTemplate={(option) => {
                    return <Tag value={option}></Tag>;
                }}
            />
        );
    };    

    const priceEditor = (options: ColumnEditorOptions) => {
        return <InputNumber value={options.value}  onValueChange={(e: InputNumberValueChangeEvent) => options.editorCallback!(e.value)} mode="currency" currency="USD" locale="en-US" />;
    };

    const statusBodyTemplate = (rowData: Product) => {
        return <Tag value={rowData.inventoryStatus} severity={getSeverity(rowData.inventoryStatus)}></Tag>;
    };

    const priceBodyTemplate = (rowData: Product) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rowData.price);
    };

    const allowEdit = (rowData: Product) => {
        return rowData.name !== 'Blue Band';
    };

    interface Product {
        id: string;
        code: string;
        name: string;
        description: string;
        image: string;
        price: number;
        category: string;
        quantity: number;
        inventoryStatus: string;
        rating: number;
    }
    
    interface ColumnMeta {
        field: string;
        header: string;
    }

    const cols: ColumnMeta[] = [
        { field: 'code', header: 'Code' },
        { field: 'name', header: 'Name' },
        { field: 'category', header: 'Category' },
        { field: 'quantity', header: 'Quantity' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    /*const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, products);
                doc.save('products.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(products);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };*/

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />            
        </div>
    );
    return (
        <>
            <div className="card m-0 p-0" style={{ height: '4vh', minHeight: '20px' }}>
                <img alt="Land CHaracterization Software" src="logo.png" height="30" className="mr-2"></img>
                <img alt="Food and Agriculture Organization of the United Nations" src="fao-logo.png" height="30" className="mr-2"></img>
            </div>
            <div className="card flex justify-content-center">
                <Button label="Back" icon="pi pi-step-backward-alt" size="small" onClick={()=>{setLegendUploadVisible(true);setConnectorVisible(false);}} style={{ backgroundColor: 'var(--highlight-bg)', color: 'var(--error-100)', borderRadius: 'var(--border-radius)' }}  className="mr-2 text-xs p-1 m-1" />
            </div>
            <div className="card flex flex-row gap-2 m-1 p-1" style={{ height: '90vh', overflow: 'auto' }}>
                <div className="card flex flex-column justify-content-center align-self-start" style={{ width: '60%' }}>
                    <Card title="Legend Allocation" className="w-full" >
                        <div className="card p-fluid">
                            <DataTable ref={dt} value={products} footer={header} editMode="row" dataKey="id" onRowEditComplete={onRowEditComplete} tableStyle={{ minWidth: '50rem' }}>
                                <Column field="class_map_code" header="Appointed Legend Class Code" style={{ width: '20%' }}></Column>
                                <Column field="class_name" header="Appointed Legend Class" style={{ width: '20%' }}></Column>
                                <Column field="matching_rules" header={"Matching "+referenceLegend.ref+" Rules"} style={{ width: '20%' }}></Column>
                                <Column field="reference_class" header={"Reference "+referenceLegend.ref+" Class"} style={{ width: '20%' }}></Column>
                                <Column field="inventoryStatus" header="User Reassignment" body={statusBodyTemplate} editor={(options) => statusEditor(options)} style={{ width: '20%' }}></Column>                                
                                <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>
                        </div>
                    </Card>
                </div>
                <div className="card flex flex-column justify-content-center align-self-start" style={{ width: '40%' }}>
                    <Card title="Resources" className="w-full" style={{ background: '#eee' }}>                    
                        <table>
                            <tbody>                                
                                <tr>                                    
                                    <td><b>Current Reference Legends</b><br />
                                        <ul>
                                            <li><a href="#">The Sytem of Environmental-Economic Accounting - Ecosystem Accounting (SEEA EA)</a></li>
                                            {/*<li><a href="#">Copernicus Global Land Service Global Land Cover</a></li>
                                            <li><a href="#">ESA WorldCover 2020</a></li>
                                            <li><a href="#">Climate Change Initiative Global Land Cover</a></li>
                                            <li><a href="#">GlobCover Land Cover Maps</a></li>
                                            <li><a href="#">The Global Land Cover-SHARE (GLC-SHARE)</a></li>
                                            <li><a href="#">Global Land Cover Mapping: A POK Based Approach (GlobeLand30)</a></li>
                                            <li><a href="#">Intergovernmental Panel on Climate Change (IPCC)</a></li>
                                            <li><a href="#">United Nations Convention to Combat Desertification (UNCCD)</a></li>
                                            <li><a href="#">ESRI Global Land Use Land Cover</a></li>
                                            <li><a href="#">Global 30m surface coverage fine classification products</a></li>
                                            <li><a href="#">Land use and land cover classification system for use with remote sensor data (Anderson)</a></li>*/}
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>                    
                </div>
            </div>
        </>
    );
}